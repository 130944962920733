import React from "react";
import { StyledAddedTodayCard } from "./Styles";
import Button from "../../../common/button/Button";

const AddedTodayCard = ({ imgUrl, forWhat, title, address, price }) => (
  <StyledAddedTodayCard className="location-item">
    <div className="location-image">
      <figure>
        <img src={imgUrl} alt="" />
      </figure>
    </div>
    <div className="location-content">
      <div className="card-over-body">
        <div className="card-body content-overlay pb-0">
          <div className="d-flex">
            <span className="badge bg-success fs-sm me-2">Verified</span>
            <span className="badge bg-info fs-sm">New</span>
          </div>
        </div>
        <div className="card-footer content-overlay border-0 pt-0 pb-4">
          <div className="d-sm-flex justify-content-between align-items-end pt-5 mt-2 mt-sm-5">
            <div className="text-decoration-none text-light pe-2">
              <div className="fs-sm text-uppercase pt-2 mb-1">{forWhat}</div>
              <h3 className="h5 text-light mb-1">{title}</h3>
              <div className="fs-sm opacity-70">
                <i className="fa fa-map-marker me-1"></i>
                {address}
              </div>
            </div>
            <div className="btn-group ms-n2 ms-sm-0 mt-3">
              <Button to="#" className="btn-default btn-de-2">
                {price}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StyledAddedTodayCard>
);

export default AddedTodayCard;
