import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/images/logo.png";

export const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <Container>
          <div className="mega-footer">
            <Row>
              <Col md={4} lg={3}>
                <div className="footer-about">
                  <div className="footer-logo">
                    <img src={Logo} alt="" />
                  </div>
                  <div className="footer-about-content">
                    <p>
                      All Home Desire is a reputable real estate firm. The firm
                      specializes in offering customers the most up-to-date
                      construction and dealing information. In addition, we
                      provide satisfying interior design solutions.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4} lg={3}>
                <div className="footer-contact-info">
                  <h3>Contact Info</h3>
                  <div className="footer-contact-box">
                    <div className="footer-info-box">
                      <div className="icon-box">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                      </div>

                      <p>7th Avenue Gaur City 1, Noida Extension</p>
                    </div>

                    <div className="footer-info-box">
                      <div className="icon-box">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </div>

                      <p>+91 9818961783</p>
                    </div>

                    <div className="footer-info-box">
                      <div className="icon-box">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                      </div>

                      <p>allhomedesire@gmail.com</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} lg={3}>
                <div className="footer-quick-links">
                  <h3>Quick Links</h3>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="#">Interior Design</a>
                    </li>
                    <li>
                      <a href="property-list">Property</a>
                    </li>
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="contactus">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
      <div className="footer-copyright-links">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="footer-copyright">
                <p>Copyright © 2024 allhomedesire. All rights reserved.</p>
              </div>
            </Col>

            <Col md={6}>
              <div className="footer-policy-links">
                <ul>
                  <li>
                    <a href="#">Privacy & Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms & Condition</a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Footer;
