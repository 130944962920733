import React from "react";
import Alert from "react-bootstrap/Alert";
import { FaCheck } from "react-icons/fa";
import Styles from './Error.module.css'
const Error = ({ message, variant, show }) => {
  return (
    show === true &&
    (variant === "success" ? (
      <>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
        
          <Alert key={variant} variant={variant} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div className={`${Styles.icon}`}>
            <FaCheck/>
            </div>
         
            {message}
          </Alert>
        </div>
      </>
    ) : (
      <Alert key={variant} variant={variant}>
        {message}
      </Alert>
    ))
  );
};

export default Error;
