import React from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { StyledOfferCard } from "./Style";

const OfferCard = ({
  title,
  description,
  imageUrl,
  btnText,
  type,
  badroom,
  bedroomsSize,
  bathroom,
  bathroomSize,
  area,
  areaSize,
  countryIcon,
  country,
  price,
  rupee,
  to,
  id,
}) => {
  // Truncate the description if it's longer than 50 characters
  let truncatedDescription = description;
  if (description.length > 50) {
    truncatedDescription = description.substring(0, 100) + "...";
  }

  return (
    <StyledOfferCard>
      <div className="property-item">
        <div className="property-header">
          <figure className="image-anime">
            <img
              src={`https://images.allhomedesire.in/${imageUrl}`}
              alt=""
              style={{ height: "20rem" }}
            />
          </figure>

          <span className="property-label">{type}</span>
        </div>
        <div className="property-body">
          <h3>{title}</h3>
          <p>{parse(truncatedDescription)}</p>

          <div className="property-meta">
            <div className="property-amenity-item">
              <div className="icon-box">
                <img src={badroom} alt="" />
              </div>

              <span>{bedroomsSize} Bedroom</span>
            </div>

            <div className="property-amenity-item">
              <div className="icon-box">
                <img src={bathroom} alt="" />
              </div>

              <span>{bathroomSize} Bathrooms</span>
            </div>

            <div className="property-amenity-item">
              <div className="icon-box">
                <img src={area} alt="" />
              </div>

              <span>{areaSize} </span>
            </div>

            <div className="property-amenity-item">
              <div className="icon-box">
                <img src={countryIcon} alt="" />
              </div>

              <span>{country}</span>
            </div>
          </div>
        </div>
        <div className="property-footer">
          <p
            className="property-price"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="icon-box">
              <img src={rupee} alt="" style={{ width: "1rem" }} />
            </div>
            {price}
          </p>
          <Link to={`/${to}/${id}`} className="btn-default">
            {btnText}
          </Link>
        </div>
      </div>
    </StyledOfferCard>
  );
};

export default OfferCard;
