import React, { useState, useEffect } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import Style from "../../../admin/UserStyle.module.css";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Api from "../../../admin/components/api/Api";
import SingleDetailHeader from "../../../admin/components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../../admin/components/singleDetail/SingleDetail";

const ContactUsItem = () => {
  const { contactId } = useParams();
  const [userDetail, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getDetailsApi = Api({
    url: `user/contact/id/${contactId}`,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchData(true);

        setUserDetails(responseData.data.contact);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container style={{ marginTop: "2rem" }}>
          <SingleDetailHeader
            header="Contact Details"
            description="See the contact details section"
          />

          <Row>
            <Col>
              <SingleDetail
                label="First Name"
                userDetail={userDetail?.first_name}
              />
            </Col>
            <Col>
              <SingleDetail
                label="Last Name"
                userDetail={userDetail?.last_name}
              />
            </Col>
            <Col>
              <SingleDetail
                label="Mobile Number"
                userDetail={userDetail?.mobile_number}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <SingleDetail
                label="Email Address"
                userDetail={userDetail?.email_address}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleDetail label="Address" userDetail={userDetail?.address} />
            </Col>
            <Col>
              <SingleDetail
                label="Address 1"
                userDetail={userDetail?.address1}
              />
            </Col>
            <Col>
              <SingleDetail label="Street" userDetail={userDetail?.street} />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleDetail
                label="Country"
                userDetail={userDetail?.country_name}
              />
            </Col>
            <Col>
              <SingleDetail label="State" userDetail={userDetail?.state_name} />
            </Col>
            <Col>
              <SingleDetail label="City" userDetail={userDetail?.city_name} />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleDetail
                label="Apartment"
                userDetail={userDetail?.apartment}
              />
            </Col>
            <Col>
              <SingleDetail
                label="Zip Code"
                userDetail={userDetail?.zip_code}
              />
            </Col>
          </Row>
          <div className={Style.btnCol}>
            <Link
              className={`btn btn-primary`}
              to={`/contact/${contactId}/edit/`}
            >
              Edit User Details
            </Link>
          </div>
        </Container>
      )}
    </>
  );
};

export default ContactUsItem;
