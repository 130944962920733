import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Spinner from "../components/common/spinner/Spinner";
import Error from "../components/common/error/Error";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createUserAction } from "../features/auth/authActions";
import Notification, {
  showToast,
} from "../components/common/notification/Notification";

const SignUp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();
  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    password: "",
    userType: {
      name: "CUSTOMER",
    },
    role: ["ROLE_ADMIN", "ROLE_MODERATOR", "ROLE_USER"],
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    const successData = await dispatch(createUserAction(values));
    try {
      setSubmitting(true);

      console.log(successData);
      if (successData.success !== true) {
        throw new Error("Signup failed");
      } else {
        if (successData.success === true) {
          showToast("Signed Up Successfully", {
            type: "success",
          });

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      }
    } catch (error) {
      setIsError(true);
      showToast(successData.data.text, {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Using Yup for form validation
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email_address: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    mobile_number: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Invalid Indian phone number")
      .required("Mobile Number is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <Container>
      <Notification />
      <Row className="justify-content-center mt-5 mb-5">
        <Col md={6}>
          <div className="card">
            <div className="card-header">
              <h4>Sign Up</h4>
            </div>

            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-3">
                      <label htmlFor="first_name" className="form-label">
                        First Name
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="first_name"
                        aria-describedby="emailHelp"
                        placeholder="Enter First Name"
                        name="first_name"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="last_name" className="form-label">
                        Last Name
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="last_name"
                        aria-describedby="emailHelp"
                        placeholder="Enter Last Name"
                        name="last_name"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        name="email_address"
                      />
                      <ErrorMessage
                        name="email_address"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="mobile_number" className="form-label">
                        Mobile Number
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="mobile_number"
                        aria-describedby="emailHelp"
                        placeholder="Enter Phone Number"
                        name="mobile_number"
                      />
                      <ErrorMessage
                        name="mobile_number"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <Field
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {isError && (
                      <Error
                        message="Incorrect Credentials"
                        variant="danger"
                        show={true}
                      />
                    )}

                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                      // disabled={true}
                    >
                      {isSubmitting ? <Spinner loading={true} /> : "Signup"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>

            <Row>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                Didn't Have An Account ?
                <Link
                  style={{
                    marginLeft: "1rem",
                  }}
                  to={"/login"}
                >
                  SignIn
                </Link>
              </p>
              <Col></Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
