// ApiComponent.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
const Api = ({ url }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const fetchData = async (auth = true) => {
    try {
      setIsLoading(true);

      let response;
      if (auth) {
        response = await axios.get(`${baseUrl}/${url}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include authorization token in the request headers
          },
        });
      } else {
        response = await axios.get(url);
      }

      setData(response.data);
      setIsLoading(false);
      return response;
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const postData = async (postData, contentType = "application/json") => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${baseUrl}/${url}`, postData, {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const editData = async (editData, contentType = "application/json") => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${baseUrl}/${url}`, editData, {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const deleteData = async () => {
    try {
      setIsLoading(true);
      console.log(url);
      const response = await axios.get(`${baseUrl}/${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setIsLoading(false);
      return response;
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, fetchData, postData, editData, deleteData };
};

export default Api;
