import { NavLink, React, Link } from "react-router-dom";
import HeroSection from "../components/homepage/HeroSection";
import PropertyByCity from "../components/homepage/PropertyByCity";
import Offer from "../components/homepage/Offer";
import Type from "../components/homepage/Type";
import AboutUs from "../components/homepage/AboutUs";
import AboutUsTwo from "../components/homepage/AboutUs2";
import AddedToday from "../components/homepage/AddedToday";
import HowItWorks from "../components/homepage/HowItWorks";
import InfoBar from "../components/homepage/InfoBar";

function HomePage() {
  return (
    <div className="home-page">
      <HeroSection />
      <PropertyByCity />
      <Type />
      <Offer />
      <AboutUs />
      <AddedToday />
      <AboutUsTwo />
      <HowItWorks />
      <InfoBar />
    </div>
  );
}

export default HomePage;
