import React from "react";
import { Link } from "react-router-dom";
const Button = ({ onClick, children, className }) => {
  return (
    <>
      <Link to="#" className={`${className}`} onClick={onClick}>
        {children}
      </Link>
    </>
  );
};

export default Button;
