import React from "react";
import { formatDistanceToNow } from "date-fns";

const TimeAgo = ({ timestamp }) => {
  // Manually parse the timestamp string into a Date object
  const [date, time] = timestamp.split(" ");
  const [day, month, year] = date.split("-").map(Number);
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);

  // Calculate the time difference and format it
  const timeAgoString = formatDistanceToNow(parsedDate, { addSuffix: true });

  return <span>{timeAgoString}</span>;
};

export default TimeAgo;
