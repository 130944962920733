import React, { useState } from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import "swiper/css";
import Col from "react-bootstrap/Col";
import Button from "../common/button/Button";
import InputField from "./form/input/Input";
import SelectField from "./form/select/Select";

const HeroSection = () => {
  const [keywords, setKeywords] = useState("");
  const [sellOrRent, setSellOrRent] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [location, setLocation] = useState("");
  const [amenities, setAmenities] = useState("");
  const handleButtonClick = (e) => {};

  return (
    <>
      <div className="hero">
        <div className="hero-section parallaxie">
          <Container className="container">
            <Row className="row align-items-center">
              <div className="col-lg-12">
                <div className="hero-content">
                  <div className="section-title">
                    <h1 className="text-anime">Find Your Best Property</h1>
                  </div>
                  <div className="hero-content-body">
                    <p>
                      Need a perfect place to live right now? Here's the best
                      offer for you! Amazing house with the most comfortable
                      layout is fully equipped with everything needed.
                    </p>
                  </div>

                  <div className="hero-content-footer">
                    <Link
                      to="/property-list"
                      className="btn-default"
                      style={{ color: "#fff" }}
                    >
                      View Property
                    </Link>
                    <Link to="/contactus" className="btn-default btn-border">
                      Contact Now
                    </Link>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div className="hero-search-form-section">
          <Container>
            <div className="hero-search-form">
              <div className="search-heading">
                <h4>Search Property</h4>
              </div>

              <form action="#" method="get">
                <div className="row">
                  <Col lg={2} md={6}>
                    <InputField
                      placeholder="Enter keywords"
                      onChange={(e) => setKeywords(e.target.value)}
                      value={keywords}
                      className="form-control"
                    />
                  </Col>

                  <Col lg={2} md={6}>
                    <SelectField
                      placeholder="Sell or Rent"
                      options={["Sell", "Rent"]}
                      onChange={(e) => setSellOrRent(e.target.value)}
                      value={sellOrRent}
                      className="form-control"
                    />
                  </Col>

                  <Col lg={2} md={6}>
                    <SelectField
                      placeholder="Property Type"
                      options={["Apartment", "Office"]}
                      onChange={(e) => setPropertyType(e.target.value)}
                      value={propertyType}
                      className="form-control"
                    />
                  </Col>

                  <Col lg={2} md={6}>
                    <SelectField
                      placeholder="Location"
                      options={["Surat", "Banglore"]}
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                      className="form-control"
                    />
                  </Col>

                  <Col lg={2} md={6}>
                    <SelectField
                      placeholder="Amenities"
                      options={["Swimming Pool", "Playarea"]}
                      onChange={(e) => setAmenities(e.target.value)}
                      value={amenities}
                      className="form-control"
                    />
                  </Col>

                  <Col lg={2} md={6}>
                    <Button
                      type="submit"
                      className="btn-default"
                      onClick={handleButtonClick}
                    >
                      <Link to="/property-list">Search</Link>
                    </Button>
                  </Col>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
