import styled from "styled-components";

export const StyledTypeCard = styled.div`
  .card-pro {
    border: none;
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
    text-align: center;
  }
  .card-pro .card-footer {
    background: transparent;
    margin-bottom: 20px;
  }
  .card-pro:hover {
    box-shadow: 0 0.125rem 0.125rem -0.125rem #1f1b2d14,
      0 0.25rem 0.75rem #1f1b2d14;
  }
  .card-pro .card-title {
    font-size: 25px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .card-pro .card-text {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .title-head-main a {
    color: #666276;
    text-decoration: none;
    font-size: 15px;
  }
  .title-head-main a i {
    padding-left: 5px;
  }
`;
