import React from "react";
import { NavLink, Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";
import Col from "react-bootstrap/Col";

import PropertyHomeIcon from "../../assets/images/icon-how-1.svg";
import RetailerHomeIcon from "../../assets/images/icon-how-2.svg";
import DocumentsHomeIcon from "../../assets/images/icon-how-3.svg";
import keysHomeIcon from "../../assets/images/icon-how-4.svg";

const HowItWorks = () => {
  return (
    <>
      <section className="how-it-works no-bg">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">
                <h3>HOW IT WORKS</h3>
                <h2 className="text-anime">Find a Perfect Home</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <div className="how-it-work-item">
                <div className="icon-box">
                  <img src={PropertyHomeIcon} alt="" />
                </div>

                <h3>Find Property</h3>
                <p>
                  Explore a wide range of properties available for sale or rent
                  in your desired location. Our platform offers a seamless
                  search experience to help you find the perfect home that meets
                  your needs and preferences.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item">
                <div className="icon-box">
                  <img src={RetailerHomeIcon} alt="" />
                </div>

                <h3>Meet Retailer</h3>
                <p>
                  Connect with experienced retailers and agents who specialize
                  in the properties you're interested in. Receive personalized
                  assistance and guidance throughout the buying or renting
                  process.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item">
                <div className="icon-box">
                  <img src={DocumentsHomeIcon} alt="" />
                </div>

                <h3>Documents</h3>
                <p>
                  Simplify the paperwork and documentation process with our
                  streamlined tools and resources. Manage all necessary
                  documents efficiently to ensure a smooth and hassle-free
                  transaction.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item">
                <div className="icon-box">
                  <img src={keysHomeIcon} alt="" />
                </div>

                <h3>Take the keys</h3>
                <p>
                  Once everything is in order and finalized, take possession of
                  your new home and receive the keys. Enjoy the excitement of
                  moving into your dream property hassle-free.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HowItWorks;
