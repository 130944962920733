import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";

const EditPost = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const { postId } = useParams(); // Assuming you're passing postId as a parameter
  const getPurposesApi = Api({
    url: `property/purposes`,
  });
  const getCityApi = Api({
    url: `admin/cities`,
  });
  const getPostApi = Api({
    url: `admin/desiredRequest/${postId}`,
  });
  const editPostApi = Api({
    url: `admin/editDesiredRequest`,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [allPurpose, setAllPurpose] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        const response = await getPostApi.fetchData(true);
        if (response.data.statusType === "SUCCESS") {
          console.log(response);
          const postData = response.data.desiredRequest;
          console.log(postData);
          setSelectedPurpose({
            label: postData.purpose.name,
            value: postData.purpose.id,
          });
          setSelectedCity(
            postData.cities.map((city) => ({
              label: city.name,
              value: city.id,
            }))
          );
          setFormData({
            title: postData.title,
            description: postData.description,
          });
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    const getPurposes = async () => {
      try {
        const responseData = await getPurposesApi.fetchData(true);
        if (responseData.data.statusType === "SUCCESS") {
          const purposeData = responseData.data.propertyPurposes.map(
            (purpose) => ({
              label: purpose.name,
              value: purpose.id,
            })
          );
          setAllPurpose(purposeData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getCity = async () => {
      try {
        const responseData = await getCityApi.fetchData(true);
        if (responseData.data.statusType === "SUCCESS") {
          const citiesData = responseData.data.cities.map((city) => ({
            label: city.name,
            value: city.id,
          }));
          setAllCity(citiesData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPostData();
    getPurposes();
    getCity();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const data = {
        id: postId,
        title: formData.title,
        description: formData.description,
        purpose: {
          id: selectedPurpose.value,
        },
        user: {
          id: userId,
        },
        desired_cities: selectedCity.map((city) => ({
          id: city.value,
        })),
      };
      console.log(data);

      const responseData = await editPostApi.editData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Post updated successfully", { type: "success" });
        setTimeout(() => {
          navigate("/post");
        }, 3000);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      showToast("Error Occurred", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <Notification />
      <Container style={{ marginTop: "2rem" }}>
        <SingleDetailHeader
          header="Edit Post Details"
          description="Edit Post details section"
        />
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Enter Title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Enter Description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <label htmlFor="wish" className="form-label">
                  I Wish TO
                </label>
                <Select
                  options={allPurpose}
                  onChange={setSelectedPurpose}
                  value={selectedPurpose}
                  placeholder="Select Purpose"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <Select
                  options={allCity}
                  isMulti
                  onChange={setSelectedCity}
                  value={selectedCity}
                  placeholder="Select City"
                />
              </div>
            </Col>
          </Row>

          <Button variant="primary" type="submit" className="mt-5 mb-5">
            {isLoading ? <Spinner loading={true} /> : "Edit Post"}
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default EditPost;
