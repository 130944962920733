import React, { useState, useEffect } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import Style from "../../UserStyle.module.css";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import Api from "../../components/api/Api";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";

const QueryItem = () => {
  const { queryId } = useParams();
  const [userDetail, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getDetailsApi = Api({
    url: `admin/desiredQuery/${queryId}`,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchData();
        console.log(responseData);
        setUserDetails(responseData.data.desiredQuery);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container style={{ marginTop: "2rem" }}>
          <SingleDetailHeader
            header="Query Details"
            description="See the query details section"
          />

          <Row>
            <Col>
              <SingleDetail
                label="Service"
                userDetail={userDetail?.service.name}
              />
            </Col>
            <Col>
              <SingleDetail
                label="Service Description"
                userDetail={userDetail?.service.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleDetail label="Name" userDetail={userDetail?.name} />
            </Col>

            <Col>
              <SingleDetail
                label="Mobile Number"
                userDetail={userDetail?.mobile_number}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleDetail
                label="Email Address"
                userDetail={userDetail?.email_address}
              />
            </Col>
          </Row>
          <Row>
            <Col className={Style.col} md={12}>
              <label className={Style.label}>Query</label>
              <textarea className={Style.textarea1}>
                {userDetail?.query}
              </textarea>{" "}
            </Col>
          </Row>
          <div className={Style.btnCol}>
            <Button className={`btn btn-primary`}>Edit User Details</Button>
          </div>
        </Container>
      )}
    </>
  );
};

export default QueryItem;
