import React, { useState, useEffect } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import Style from "../../UserStyle.module.css";

const SingleDetail = ({ label, userDetail }) => {
  return (
    <>
      <Container style={{ marginTop: "2rem" }}>
        <Col className={Style.col}>
          <label className={Style.label}>{label}</label>
          <input
            type="text"
            placeholder="First Name"
            className={`${Style.input}`}
            value={userDetail}
          />
        </Col>
      </Container>
    </>
  );
};

export default SingleDetail;
