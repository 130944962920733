import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Container } from "react-bootstrap";
import Api from "../../components/api/Api";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Style from "../../UserStyle.module.css";
import parse from "html-react-parser";

const PropertyItem = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [assetUrl, setAssetUrl] = useState("");
  const { propertyId } = useParams();
  const singlePropertyData = Api({
    url: `property/propertyDetail/${propertyId}`,
  });
  useEffect(() => {
    try {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const responseData = await singlePropertyData.fetchData();
          console.log(responseData);
          setPropertyDetails(responseData.data.propertyInfo);
          setAssetUrl(responseData.data.assetUrl);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div
            className="main-wrapper"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "2rem",
            }}
          >
            <div className="slider-container">
              <Container>
                <Swiper
                  spaceBetween={50}
                  slidesPerView={3}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {propertyDetails?.images?.map((singleImage) => (
                    <SwiperSlide>
                      <img
                        src={`${assetUrl}${singleImage.image_url}`}
                        style={{ height: "30rem" }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Container>
            </div>
            <div className="container"></div>

            <section
              className="gray-simple "
              style={{
                marginTop: "2rem",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="property_block_wrap style-2 p-4">
                      <div className="prt-detail-title-desc">
                        <span className="label text-light bg-success">
                          {propertyDetails?.type?.name}
                        </span>
                        <h3>{propertyDetails?.name}</h3>
                        <span>
                          <i className="lni-map-marker"></i>{" "}
                          {propertyDetails?.address}
                        </span>
                        <h3 className="prt-price-fix text-primary">
                          {propertyDetails?.price}
                          <sub>/month</sub>
                        </h3>
                        <div className="list-fx-features">
                          <div className="listing-card-info-icon">
                            <div className="inc-fleat-icon me-1">
                              <i className="fa-solid fa-bed"></i>
                            </div>
                            {propertyDetails?.structure?.bed_rooms} beds
                          </div>
                          <div className="listing-card-info-icon">
                            <div className="inc-fleat-icon me-1">
                              <i className="fa-solid fa-bath"></i>
                            </div>
                            {propertyDetails?.structure?.bath_rooms} Bath
                          </div>
                          <div className="listing-card-info-icon">
                            <div className="inc-fleat-icon me-1">
                              <i className="fa-solid fa-chart-area"></i>
                            </div>
                            5000 sqft
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="property_block_wrap style-2">
                      <div className="property_block_wrap_header">
                        <a
                          data-bs-toggle="collapse"
                          data-parent="#features"
                          data-bs-target="#clOne"
                          aria-controls="clOne"
                          href="javascript:void(0);"
                          aria-expanded="false"
                        >
                          <h4 className="property_block_title">
                            Detail & Features
                          </h4>
                        </a>
                      </div>
                      <div
                        id="clOne"
                        className="panel-collapse collapse show"
                        aria-labelledby="clOne"
                      >
                        <div className="block-body">
                          <ul className="deatil_features">
                            <li>
                              <strong>Bedrooms:</strong>{" "}
                              {propertyDetails?.structure?.bed_rooms} Beds
                            </li>
                            <li>
                              <strong>Bathrooms:</strong>
                              {propertyDetails?.structure?.bath_rooms} Bath
                            </li>
                            <li>
                              <strong>Areas:</strong>
                              {propertyDetails?.area?.name}
                            </li>

                            <li>
                              <strong>Property Type:</strong>{" "}
                              {propertyDetails?.type?.name}
                            </li>
                            <li>
                              <strong>Pincode:</strong>{" "}
                              {propertyDetails?.pin_code}
                            </li>
                            <li>
                              <strong>Status:</strong>{" "}
                              {propertyDetails?.status?.name}
                            </li>
                            <li>
                              <strong>Total Rooms:</strong>{" "}
                              {propertyDetails?.structure?.rooms}
                            </li>
                            <li>
                              <strong>Purpose:</strong>{" "}
                              {propertyDetails?.purpose?.name}
                            </li>

                            {propertyDetails?.feature?.swimming_pool ===
                            true ? (
                              <li>
                                <strong>Swimming Pool:</strong> Yes
                              </li>
                            ) : (
                              <li>
                                <strong>Swimming Pool:</strong> No
                              </li>
                            )}
                            {propertyDetails?.feature?.school_near_by ===
                            true ? (
                              <li>
                                <strong>School NearBy:</strong>Yes
                              </li>
                            ) : (
                              <li>
                                <strong>School NearBy:</strong>No
                              </li>
                            )}
                            {propertyDetails?.feature?.market_near_by ===
                            true ? (
                              <li>
                                <strong>Market NearBy:</strong>Yes
                              </li>
                            ) : (
                              <li>
                                <strong>Market NearBy:</strong>No
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="property_block_wrap style-2">
                      <div className="property_block_wrap_header">
                        <a
                          data-bs-toggle="collapse"
                          data-parent="#dsrp"
                          data-bs-target="#clTwo"
                          aria-controls="clTwo"
                          href="javascript:void(0);"
                          aria-expanded="true"
                        >
                          <h4 className="property_block_title">Description</h4>
                        </a>
                      </div>
                      <div id="clTwo" className="panel-collapse collapse show">
                        <div className="block-body">
                          {/* <p>{data?.description}</p> */}
                          {parse(`${propertyDetails?.description}`)}
                          {/* <p>{ReactHtmlParser(propertyDetails.description)}</p> */}
                        </div>
                      </div>
                    </div>

                    <div className="property_block_wrap style-2">
                      <div className="property_block_wrap_header">
                        <a
                          data-bs-toggle="collapse"
                          data-parent="#amen"
                          data-bs-target="#clThree"
                          aria-controls="clThree"
                          href="javascript:void(0);"
                          aria-expanded="true"
                        >
                          <h4 className="property_block_title">Ameneties</h4>
                        </a>
                      </div>

                      <div
                        id="clThree"
                        className="panel-collapse collapse show"
                      >
                        <div className="block-body">
                          <ul className="avl-features third color">
                            {propertyDetails &&
                              propertyDetails.feature &&
                              Object.entries(propertyDetails.feature).map(
                                ([key, value]) => {
                                  if (
                                    typeof value === "boolean" &&
                                    value === true &&
                                    key !== "active"
                                  ) {
                                    return (
                                      <li key={key}>
                                        {key.replace(/_/g, " ")}
                                      </li>
                                    );
                                  }
                                  return null;
                                }
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="property_block_wrap style-2">
                      <div className="property_block_wrap_header">
                        <a
                          data-bs-toggle="collapse"
                          data-parent="#vid"
                          data-bs-target="#clFour"
                          aria-controls="clFour"
                          href="javascript:void(0);"
                          aria-expanded="true"
                          className="collapsed"
                        >
                          <h4 className="property_block_title">
                            Property video
                          </h4>
                        </a>
                      </div>

                      <div id="clFour" className="panel-collapse collapse">
                        <div className="block-body">
                          <div className="property_video">
                            <div className="thumb">
                              <img
                                className="pro_img img-fluid w100"
                                src="assets/img/pl-6.jpg"
                                alt="7.jpg"
                              />
                              <div className="overlay_icon">
                                <div className="bb-video-box">
                                  <div className="bb-video-box-inner">
                                    <div className="bb-video-box-innerup">
                                      <a
                                        href="https://www.youtube.com/watch?v=A8EI6JaFbv4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#popup-video"
                                        className="text-primary"
                                      >
                                        <i className="fa-solid fa-play"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="property_block_wrap style-2">
                      <div className="property_block_wrap_header">
                        <a
                          data-bs-toggle="collapse"
                          data-parent="#floor"
                          data-bs-target="#clFive"
                          aria-controls="clFive"
                          href="javascript:void(0);"
                          aria-expanded="true"
                          className="collapsed"
                        >
                          <h4 className="property_block_title">Floor Plan</h4>
                        </a>
                      </div>

                      <div id="clFive" className="panel-collapse collapse">
                        <div className="block-body">
                          <div className="accordion" id="floor-option">
                            <div className="card">
                              <div className="card-header" id="firstFloor">
                                <h2 className="mb-0">
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#firstfloor"
                                    aria-controls="firstfloor"
                                  >
                                    First Floor<span>740 sq ft</span>
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="firstfloor"
                                className="collapse"
                                aria-labelledby="firstFloor"
                                data-parent="#floor-option"
                              >
                                <div className="card-body">
                                  <img
                                    src="assets/img/floor.jpg"
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="seconfFloor">
                                <h2 className="mb-0">
                                  <button
                                    type="button"
                                    className="btn btn-link collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#secondfloor"
                                    aria-controls="secondfloor"
                                  >
                                    Second Floor<span>710 sq ft</span>
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="secondfloor"
                                className="collapse"
                                aria-labelledby="seconfFloor"
                                data-parent="#floor-option"
                              >
                                <div className="card-body">
                                  <img
                                    src="assets/img/floor.jpg"
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="third-garage">
                                <h2 className="mb-0">
                                  <button
                                    type="button"
                                    className="btn btn-link collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#garages"
                                    aria-controls="garages"
                                  >
                                    Garage<span>520 sq ft</span>
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="garages"
                                className="collapse"
                                aria-labelledby="third-garage"
                                data-parent="#floor-option"
                              >
                                <div className="card-body">
                                  <img
                                    src="assets/img/floor.jpg"
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="property_block_wrap style-2">
                      <div className="property_block_wrap_header">
                        <a
                          data-bs-toggle="collapse"
                          data-parent="#loca"
                          data-bs-target="#clSix"
                          aria-controls="clSix"
                          href="javascript:void(0);"
                          aria-expanded="true"
                          className="collapsed"
                        >
                          <h4 className="property_block_title">Location</h4>
                        </a>

                        <ul className="deatil_features mt-4">
                          <li>
                            <strong>Country:</strong>{" "}
                            {propertyDetails?.area?.city?.state?.country?.name}{" "}
                          </li>
                          <li>
                            <strong>State:</strong>{" "}
                            {propertyDetails?.area?.city?.state?.name}{" "}
                          </li>
                          <li>
                            <strong>City:</strong>{" "}
                            {propertyDetails?.area?.city?.name}{" "}
                          </li>
                          <li>
                            <strong>Address:</strong> {propertyDetails?.address}
                          </li>
                        </ul>
                      </div>

                      <div id="clSix" className="panel-collapse collapse">
                        <div className="block-body">
                          <div className="map-container">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.3838103135677!2d80.87929001488125!3d26.827742183164247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfe8bc34b51bb%3A0xa3ca86eec63f6f8!2sINFOSYS%20DIGITAL%20COMPUTER%20(Prabhat%20Computer%20classNamees)!5e0!3m2!1sen!2sin!4v1680238790732!5m2!1sen!2sin"
                              width="100%"
                              height="450"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="myWork" className="property_block_wrap style-2">
                      <div className="property_block_wrap_header">
                        <a
                          data-bs-toggle="collapse"
                          data-parent="#clSev"
                          data-bs-target="#clSev"
                          aria-controls="clOne"
                          href="javascript:void(0);"
                          aria-expanded="true"
                          className="collapsed"
                        >
                          <h4 className="property_block_title">Gallery</h4>
                        </a>

                        {/* {

                        } */}
                      </div>

                      <div id="clSev" className="panel">
                        <div className="block-body">
                          <ul className="list-gallery-inline">
                            {/* {
                            data?.media_id?.media_url?.map((singleImage) => (
                              <li>
                              <a
                              
                                className="mfp-gallery"
                              >
                                <Zoom>
                                <img
                                  src={singleImage?.secure_url}
                                  className="img-fluid mx-auto"
                                  alt=""
                                />
                                </Zoom>
                            
                              </a>
                            </li>
                            ))
                          } */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default PropertyItem;
