import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import AdminHeader from "../../common/Header";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Spinner from "../../../components/common/spinner/Spinner";
import Error from "../../../components/common/error/Error";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import FileUploadField from "../../../components/fileUpload/FIleUploadField";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../components/api/Api";
const AddProperty = () => {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [purpose, setPurpose] = useState("");
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [areaSuggestions, setAreaSuggestions] = useState([]);
  const [areaInput, setAreaInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [textData, setTextData] = useState("Property Description");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isAttachmentUploaded, setIsAttachmentUploaded] = useState(false);
  const [uploadedImageNames, setUploadedImageNames] = useState([]);
  const [uploadedFilesNames, setUploadedFilesNames] = useState([]);

  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const initialValues = {
    type: "", // Default to Rent
    name: "",
    status: "",
    country: "",
    state: "",
    city: "",
    area: "",
    pin_code: "",
    featured_property: "",
    address: "",
    bed_rooms: "",
    bath_rooms: "",
    rooms: "",
    description: "",
    price: "",
    swimming_pool: false,
    gas_connection: false,
    ro: false,
    club_house: false,
    basketball_court: false,
    tennis_court: false,
    gym: false,
    indoor_games: false,
    child_play_area: false,
    hospital_near_by: false,
    mall_near_by: false,
    schoold_near_by: false,
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    pin_code: Yup.string().required("Pin Code is required"),
    address: Yup.string().required("Address is required"),
  });
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log(values);
      const propertyData = {
        user: {
          id: userId,
        },
        city: {
          id: +values.city,
        },
        state: {
          id: +selectedState,
        },
        country: {
          id: +selectedCountry,
        },
        area: {
          id: 0,
          city: {
            id: +values.city,
          },
          name: "hey",
          description: "",
        },
        type: {
          id: 6,
        },
        // type: {
        //   id: +values.type,
        // },
        purpose: {
          id: +values.propertyPurpose[0],
        },
        status: {
          id: +values.status,
        },

        structure: {
          bed_rooms: +values.bedrooms,
          bath_rooms: +values.bathrooms,
          rooms: +values.rooms,
        },

        feature: {
          swimming_pool: values.swimming_pool,
          gas_connection: values.gas_connection,
          ro: values.ro,
          club_house: values.club_house,
          basketball_court: values.basketball_court,
          tennis_court: values.tennis_court,
          gym: values.gym,
          indoor_games: values.indoor_games,
          child_play_area: values.child_play_area,
          hospital_near_by: values.hospital_near_by,
          mall_near_by: values.mall_near_by,
          schoold_near_by: values.schoold_near_by,
        },
        images: uploadedImageNames.map((imageName) => ({
          imagePath: imageName,
        })),

        attachments: uploadedFilesNames.map((attachmentName) => ({
          attachmentPath: attachmentName,
        })),

        videos: [
          {
            url_path: values.videoURL,
          },
        ],
        tags: values.tags,
        name: values.name,
        description: textData,
        address: values.address,
        land_mark: "",
        pin_code: values.pin_code,
        price: values.price,
        featured_property: values.featured_property,
      };
      console.log(propertyData);
      console.log(values);
      setSubmitting(true);

      const response = await axios.post(
        `${baseUrl}/property/addProperty`,
        propertyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);

      if (response.data.statusType === "SUCCESS") {
        console.log("Property created successfully:", response);
        // toast.success("Property added successfully");
        // navigate("/property");
        showToast("Property added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/property");
        }, 3000);
      } else {
        console.error("Error creating property:", response.text);
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setSubmitting(false);
    }
  };

  // Using Yup for form validation

  // all functions starts here

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await axios.get(`${baseUrl}/admin/countries`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }

        const purposeResponse = await axios.get(
          `${baseUrl}/property/purposes`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (purposeResponse.data.statusType === "SUCCESS") {
          setPropertyPurposes(purposeResponse.data.propertyPurposes);
        } else {
          setPropertyPurposes([]);
        }

        const typeResponse = await axios.get(`${baseUrl}/property/types`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (typeResponse.data.statusType === "SUCCESS") {
          setPropertyTypes(typeResponse.data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }

        const statusResponse = await axios.get(
          `${baseUrl}/property/statusList`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (statusResponse.data.statusType === "SUCCESS") {
          setStatusList(statusResponse.data.propertyStatuss);
        } else {
          setStatusList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          console.log("Your Country id is", countryId);
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.get(
              `${baseUrl}/admin/states/${countryId}`,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          console.log("your stateid is ", stateId);
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.get(
              `${baseUrl}/admin/cities/${stateId}`,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedCountry, selectedState, loggedInUser?.token]);

  const getAllPropertyType = () => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAreaSuggestions = async (input) => {
    try {
      console.log(input);
      const response = await axios.get(
        `${baseUrl}/admin/areasByName/${input}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.statusType === "SUCCESS") {
        setAreaSuggestions(response.data.areas);
        console.log(response);
      } else {
        setAreaSuggestions([]);
      }
    } catch (error) {
      console.error("Error fetching area list:", error);
      setAreaSuggestions([]);
    }
  };

  const uploadImages = async (values) => {
    console.log(values);
    const formData = new FormData();
    values.files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `${baseUrl}/all/upload/files/images`,
        formData
      );
      console.log(response);

      if (response.data.statusType === "SUCCESS") {
        console.log("hello from data");
        const imagePaths = response.data.propertyImages.map(
          (image) => image.imagePath
        );
        setUploadedImageNames(imagePaths);

        setIsImageUploaded(true);
      }
    } catch (error) {
      console.log(error);
      alert("Please select one or more attachments to upload.");
    }
  };

  const attachment = async (values, { setSubmitting }) => {
    try {
      console.log(values);
      const formData = new FormData();
      values.files.forEach((file) => formData.append("files", file));

      try {
        const response = await axios.post(
          `${baseUrl}/all/upload/files/attachments`,
          formData
        );
        console.log(response.data.propertyAttachments);

        if (response.data.statusType === "SUCCESS") {
          const attachmentPaths = response.data.propertyAttachments.map(
            (attachment) => attachment.attachmentPath
          );
          setUploadedFilesNames(attachmentPaths);
          setIsAttachmentUploaded(true);
        }
      } catch (error) {
        console.log(error);
        alert("Please select one or more attachments to upload.");
      }
      // await uploadFiles(formData);
    } finally {
      setSubmitting(false);
    }
  };
  const show = () => {
    showToast("Property added successfully", {
      type: "success",
    });
  };
  return (
    <>
      <Notification />
      <Container>
        {/* <button onClick={show}>click me</button> */}
        <Row className="mt-5 mb-5">
          <Col md="2">
            <AdminHeader />
          </Col>
          <Col md={10}>
            <div className="card">
              <div className="card-header">
                <h4>Property Listing</h4>
              </div>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <div className="card-body">
                      <div className="card mb-4">
                        <div className="card-header">
                          <p>Add Property Information</p>
                        </div>
                        <div className="card-body">
                          <Row>
                            <label
                              htmlFor="title"
                              className="form-label mt-1 mb-2"
                            >
                              I Wish To:
                            </label>
                            <FieldArray name="propertyPurpose">
                              {({ push }) => (
                                <>
                                  {propertyPurposes.map((purpose) => (
                                    <Col>
                                      <div
                                        key={purpose.id}
                                        className="form-check"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="propertyPurpose"
                                          id={`purpose-${purpose.id}`}
                                          value={purpose.id}
                                          onChange={(e) => {
                                            push(e.target.value);
                                            setPurpose(e.target.value);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`purpose-${purpose.id}`}
                                        >
                                          {purpose.name}
                                        </label>
                                      </div>
                                    </Col>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </Row>
                          <ErrorMessage
                            name="propertyType"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="card mb-4">
                        <div className="card-header">
                          <p>Property Details</p>
                        </div>
                        <div className="card-body">
                          <Row className="mb-3">
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="price" className="form-label">
                                  Price
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  placeholder="Enter property Name"
                                  name="price"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  Property Name
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  placeholder="Enter property Name"
                                  name="name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  Property Type*
                                </label>
                                <Field
                                  as="select"
                                  className="form-control"
                                  name="propertyType"
                                  required=""
                                >
                                  <option value="">
                                    -- Any Property Type --
                                  </option>
                                  {propertyTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  Status
                                </label>
                                <Field
                                  as="select"
                                  className="form-control"
                                  name="status"
                                  required=""
                                >
                                  <option value="">
                                    -- Any Status Type --
                                  </option>
                                  {statusList.map((status) => (
                                    <option key={status.id} value={status.id}>
                                      {status.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  Country
                                </label>
                                <Field
                                  as="select"
                                  className="form-control"
                                  name="country"
                                  required=""
                                >
                                  <option value="">-- Select Country --</option>
                                  {countries.map((country) => (
                                    <option key={country.id} value={country.id}>
                                      {country.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  State
                                </label>
                                <select
                                  className="form-control"
                                  name="state"
                                  required=""
                                  onChange={(e) =>
                                    setSelectedState(e.target.value)
                                  }
                                >
                                  <option value="">-- Select State --</option>
                                  {states.map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  City
                                </label>
                                <Field
                                  as="select"
                                  className="form-control"
                                  name="city"
                                  required=""
                                >
                                  <option value="">-- Select City --</option>
                                  {cities.map((city) => (
                                    <option key={city.id} value={city.id}>
                                      {city.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  Area
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  placeholder="Enter Area"
                                  value={areaInput}
                                  onChange={(e) => {
                                    setAreaInput(e.target.value);
                                    fetchAreaSuggestions(e.target.value);
                                  }}
                                  name="area"
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  Zip code
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  placeholder="Enter property Name"
                                  name="pin_code"
                                />
                                <ErrorMessage
                                  name="pin_code"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="title" className="form-label">
                                  Featured Property
                                </label>
                                <Field
                                  as="select"
                                  className="form-control"
                                  name="featured_property"
                                  required=""
                                >
                                  <option value="">-- Choose --</option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </Field>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md={12}>
                              <div className="">
                                <label
                                  htmlFor="description"
                                  className="form-label"
                                >
                                  Address
                                </label>
                                <Field
                                  as="textarea"
                                  className="form-control"
                                  id="address"
                                  rows="3"
                                  placeholder="Address"
                                  name="address"
                                />
                                <ErrorMessage
                                  name="address"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="card mb-4">
                        <div className="card-header">
                          <p>Internal Structure</p>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col md={4}>
                              <div className="">
                                <label
                                  htmlFor="bedrooms"
                                  className="form-label"
                                >
                                  Bedrooms
                                </label>
                                <Field
                                  type="number"
                                  className="form-control"
                                  id="bedrooms"
                                  placeholder="Enter Bedrooms"
                                  name="bedrooms"
                                />
                                <ErrorMessage
                                  name="bedrooms"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label
                                  htmlFor="bathrooms"
                                  className="form-label"
                                >
                                  Bathrooms
                                </label>
                                <Field
                                  type="number"
                                  className="form-control"
                                  id="bathrooms"
                                  placeholder="Enter Bathrooms"
                                  name="bathrooms"
                                />
                                <ErrorMessage
                                  name="bathrooms"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="rooms" className="form-label">
                                  Rooms
                                </label>
                                <Field
                                  type="number"
                                  className="form-control"
                                  id="rooms"
                                  placeholder="Enter Rooms"
                                  name="rooms"
                                />
                                <ErrorMessage
                                  name="rooms"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="card mb-4">
                        <div className="card-header">
                          <p>Features</p>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  id="SwimmingPool"
                                  name="swimming_pool"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Swimming Pool
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  id="GasConnection"
                                  name="gas_connection"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Gas Connection
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  id="RO"
                                  name="ro"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  RO
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  class="form-check-input"
                                  type="checkbox"
                                  id="ClubHouse"
                                  name="club_house"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Club House
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  id="BasketballCourt"
                                  name="basketball_court"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Basketball Court
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  class="form-check-input"
                                  type="checkbox"
                                  id="TennisCourt"
                                  name="tennis_court"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Tennis Court
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  class="form-check-input"
                                  type="checkbox"
                                  id="Gym"
                                  name="gym"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Gym
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  class="form-check-input"
                                  type="checkbox"
                                  id="IndoorGames"
                                  name="indoor_games"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Indoor Games
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  class="form-check-input"
                                  type="checkbox"
                                  id="ChildPlayArea"
                                  name="child_play_area"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Child Play Area
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  class="form-check-input"
                                  type="checkbox"
                                  id="HospitalNearBy"
                                  name="hospital_near_by"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Hospital Near By
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  class="form-check-input"
                                  type="checkbox"
                                  id="MallNearBy"
                                  name="mall_near_by"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Mall Near By
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <Field
                                  class="form-check-input"
                                  type="checkbox"
                                  id="MarketNearBy"
                                  name="schoold_near_by"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Market Near By
                                </label>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div class="form-check mb-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="SchoolNearBy"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  School Near By
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="card mb-4">
                        <div className="card-header">
                          <p>Video</p>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col md={12} className="mb-3">
                              <label htmlFor="title" className="form-label">
                                Video URL
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="title"
                                placeholder="Video URL"
                                name="videoURL" // Add a name attribute for Formik to track the field
                              />
                            </Col>
                            <Col md={12} className="mb-3">
                              <label htmlFor="title" className="form-label">
                                Tags (Each tag separated by comma ,)
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="title"
                                placeholder="Tags"
                                name="tags"
                              />
                              <ErrorMessage
                                name="tags"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="card mb-4">
                        <div className="card-header">
                          <p>Property Description</p>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col md={12} className="mb-3">
                              <div className="ckeditor-component">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={textData}
                                  onChange={(event, editor) =>
                                    setTextData(editor.getData())
                                  }
                                  required
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <button type="submit" className="btn btn-primary">
                        {isSubmitting ? (
                          <Spinner loading={true} />
                        ) : (
                          "Create Property"
                        )}
                      </button>
                    </div>

                    {isError && (
                      <Error
                        message="Incorrect Credentials"
                        variant="danger"
                        show={true}
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </div>
            <div className="card mt-5">
              <div className="card-header">
                <h4>Attachments</h4>
              </div>
              <Formik
                initialValues={{
                  files: [],
                }}
                onSubmit={attachment}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="card-body"></div>
                    <div className="mb-3"></div>
                    <Row>
                      <Col md={12}>
                        <div
                          className="mb-3"
                          style={{
                            padding: "2rem 2rem",
                          }}
                        >
                          {/* <Field
                          className="form-control"
                          type="file"
                          id="formFile"
                        /> */}
                          <Field name="files" component={FileUploadField} />
                          <ErrorMessage
                            name="files"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div style={{ padding: "2rem 2rem" }}>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <Spinner loading={true} /> : "Upload"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

              {isAttachmentUploaded === true ? (
                <Error
                  variant="success"
                  message="Attachment uploaded successfully"
                  show={true}
                />
              ) : (
                <h1></h1>
              )}
            </div>
            <div className="card mt-5">
              <div className="card-header">
                <h4>Images</h4>
              </div>
              <Formik
                initialValues={{
                  name: "",
                  files: [],
                }}
                onSubmit={(values) => uploadImages(values)}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="card-body"></div>
                    <div className="mb-3"></div>
                    <Row>
                      <Col md={12}>
                        <div
                          className="mb-3"
                          style={{
                            padding: "2rem 2rem",
                          }}
                        >
                          <Field name="files" component={FileUploadField} />
                          <ErrorMessage
                            name="files"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div style={{ padding: "2rem 2rem" }}>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <Spinner loading={true} /> : "Upload"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

              {isImageUploaded === true ? (
                <Error
                  variant="success"
                  message="images uploaded successfully"
                  show={true}
                />
              ) : (
                <h1></h1>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddProperty;
