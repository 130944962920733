import React from "react";
import { useSelector } from "react-redux";

const Protected = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  console.log(user);
  if (user && user.userDetails && user.statusType === "SUCCESS") {
    return children;
  }

  return null;
};

export default Protected;
