import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import { useSelector, useDispatch } from "react-redux";
export const Dashboard = (props) => {
  const authState = useSelector((state) => state.auth);
  console.log(authState);
  if (!authState || authState.loading === true) {
    console.log("hello");
    return <div>Loading....</div>;
  }

  return (
    <>
      <Container className="mt-5 mb-5">
        <Row>
          <Col md="2">
            <AdminHeader />
          </Col>
          <Col md={10}>
            <div className="d-sm-flex align-items-center justify-content-between">
              <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
              <Link
                to=""
                className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              >
                <i className="fa fa-download fa-sm text-white-50"></i> Generate
                Report
              </Link>
            </div>
            <Row>
              <Col xl={3} md={6} className="mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Earnings (Monthly)
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          $40,000
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fa fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={3} md={6} className="mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                          Earnings (Annual)
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          $215,000
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fa fa-dollar-sign fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={3} md={6} className="mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                          Earnings (Annual)
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          $215,000
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fa fa-clipboard fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={3} md={6} className="mb-4">
                <div className="card border-left-warning shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                          Earnings (Annual)
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          $215,000
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fa fa-comments fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Dashboard;
