import React, { useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import { useSelector } from "react-redux";

const List = ({
  title,
  data,
  columns,
  button,
  to,
  route,
  handleDelete,
  user,
  role,
}) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userType = loggedInUser?.userDetails?.userType;
  console.log(userType);
  const userId = loggedInUser?.userDetails?.id;
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  console.log(role);
  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    setItemIdToDelete(null);
  };

  const handleDeleteAlertShow = (itemId) => {
    setShowDeleteAlert(true);
    setItemIdToDelete(itemId);
  };

  const handleDeleteConfirmed = () => {
    handleDelete(itemIdToDelete);
    handleDeleteAlertClose();
  };

  const sortedData =
    data &&
    [...data]?.sort((a, b) => {
      if (a.user?.id === userId) return -1;
      if (b.user?.id === userId) return 1;
      return 0;
    });

  return (
    <>
      <Container>
        <Alert
          show={showDeleteAlert}
          variant="danger"
          onClose={handleDeleteAlertClose}
          dismissible
        >
          <Alert.Heading>Confirm Delete</Alert.Heading>
          <p>Are you sure you want to delete this item?</p>
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-secondary"
              onClick={handleDeleteAlertClose}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirmed}>
              Delete
            </Button>
          </div>
        </Alert>

        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h4 className="card-title">{title}</h4>
            <Link to={to}>{button}</Link>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-responsive-md rimary-table-bordered">
                <thead>
                  <tr className="table-sub-header">
                    {columns.map((column, index) => (
                      <th key={index}>{column.displayName}</th>
                    ))}
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((item, index) => (
                    <tr key={index}>
                      {columns?.map((column, columnIndex) => (
                        <td key={columnIndex}>
                          {column.key?.includes(".")
                            ? getPropertyByPath(item, column.key)
                            : item[column.key]}{" "}
                        </td>
                      ))}
                      <td>
                        <div className="button-list">
                          {(userType === "SUPER_ADMIN" ||
                            userType === "ADMIN" ||
                            (userType === "PARTNER" && role === "admin") ||
                            item?.user?.id === userId) && (
                            <>
                              <button
                                className="btn btn-danger me-2"
                                onClick={() => handleDeleteAlertShow(item.id)}
                              >
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                ></i>
                                Delete
                              </button>
                              <button className="btn btn-primary me-2">
                                <Link
                                  style={{ color: "#fff" }}
                                  to={`/${route}/${item.id}/edit`}
                                >
                                  <i className="fa fa-edit"></i>
                                  Edit
                                </Link>
                              </button>
                            </>
                          )}
                          {user === true &&
                            (userType === "SUPER_ADMIN" ||
                              userType === "ALL_HOME_DESIRE") && (
                              <button className="btn btn-info me-2">
                                <Link
                                  style={{ color: "#fff" }}
                                  to={`/${route}/${item.id}/changepassword`}
                                >
                                  <i
                                    className="fa fa-password"
                                    aria-hidden="true"
                                  ></i>
                                  Change Password
                                </Link>
                              </button>
                            )}
                          <button className="btn btn-info me-2">
                            <Link
                              style={{ color: "#fff" }}
                              to={`/${route}/${item.id}`}
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              View
                            </Link>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const getPropertyByPath = (obj, path) => {
  const keys = path.split(".");
  let value = obj;
  for (let key of keys) {
    value = value[key];
  }
  return value;
};

export default List;
