import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InputField from "../components/homepage/form/input/Input";
import { login } from "../features/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, Navigate } from "react-router-dom";
import Spinner from "../components/common/spinner/Spinner";
import Error from "../components/common/error/Error";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification, {
  showToast,
} from "../components/common/notification/Notification";
import { setCredentials } from "../features/auth/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
    rememberMe: false,
  });

  const initialValues = {
    user_name: "",
    password: "",
    rememberMe: false,
  };

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth);

  useEffect(() => {
    console.log(isAuthenticated);
    if (isAuthenticated !== null) {
      if (isAuthenticated?.statusType === "SUCCESS") {
        navigate("/admin");
      }
    }
  }, [isAuthenticated, navigate]);
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setIsLoading(true);
      const baseUrl = process.env.REACT_APP_BASE_URL;
      console.log(baseUrl);
      setSubmitting(true);
      console.log(values);
      const checks = await dispatch(login(values));
      console.log("Hello all checks are ", checks);

      if (checks?.data?.statusType === "SUCCESS") {
        navigate("/admin");
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      setIsError(true);
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };
  // window.location.reload();
  // Redirect to the login page
  // window.location.href = "http://localhost:3000/admin";
  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    handleChange(name, checked);
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <Spinner loading={true} />
        ) : (
          <Row className="justify-content-center mt-5 mb-5">
            <Col md={6}>
              <div className="card">
                <div className="card-header">
                  <h4>Login</h4>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={initialValues}
                    validate={(values) => {
                      const errors = {};
                      if (!values.user_name) {
                        errors.user_name = "Required";
                      }
                      if (!values.password) {
                        errors.password = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email address
                          </label>
                          <Field
                            type="email"
                            className="form-control"
                            id="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                            name="user_name"
                          />
                          <ErrorMessage
                            name="user_name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <Field
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Password"
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-check mb-3">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMe"
                            name="rememberMe"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rememberMe"
                          >
                            Remember me
                          </label>
                        </div>
                        {isError && (
                          <Error
                            message="Incorrect Credentials"
                            variant="danger"
                            show={true}
                          />
                        )}

                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          // disabled={true}
                        >
                          {isSubmitting ? <Spinner loading={true} /> : "Login"}
                        </button>
                      </Form>
                    )}
                  </Formik>

                  <Row>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      Didn't Have An Account ?
                      <Link
                        style={{
                          marginLeft: "1rem",
                        }}
                        to={"/sign-up"}
                      >
                        SignUp
                      </Link>
                    </p>
                    <Col></Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Login;
