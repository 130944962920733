import React from "react";
import { Col } from "react-bootstrap";

const SelectField = ({ placeholder, options, onChange, className, value }) => (
  <select
    className={`form-control ${className}`}
    onChange={onChange}
    value={value}
  >
    <option value="">{placeholder}</option>
    {options.map((option) => (
      <option key={option}>{option}</option>
    ))}
  </select>
);

export default SelectField;
