import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";
import Col from "react-bootstrap/Col";

import PropertyImg4 from "../../assets/images/property-4.jpg";
import Button from "../common/button/Button";

const AboutUs = () => {
  return (
    <>
      <section className="why-choose-us">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} orderLg={2}>
              <div className="why-choose-us-image">
                <figure className="reveal image-anime">
                  <img src={PropertyImg4} alt="" />
                </figure>
              </div>
            </Col>
            <Col lg={6} orderLg={1}>
              <div className="why-choose-us-content">
                <div className="section-title">
                  <h3 className="wow fadeInUp">About Company</h3>
                  <h2 className="text-anime">
                    We Will Help You Find Your Wonderful Property
                  </h2>
                </div>

                <div className="why-choose-us-body">
                  <p>
                    <strong>All Home Desire</strong> is a reputable real estate
                    Platform. The firm specializes in offering customers the
                    most up-to-date construction and dealing information. In
                    addition, we provide satisfying interior design solutions
                    and the best thing about us we provide both services at a
                    reasonable cost.
                  </p>
                  <p>
                    Besides being well equipped to meet all human needs, our
                    facilities are surrounded by international schools,
                    transportation options, shopping centers, and gardens. We
                    assist you with everything related to property research,
                    loans, investments, and EMI calculations.
                  </p>

                  <Button href="#" className="btn-default">
                    Read More
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutUs;
