import React, { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import Styles from "./Style.module.css";
const FileUploadField = ({ field, form }) => {
  const { setFieldValue } = form;
  const [selectedImages, setSelectedImages] = useState([]);

  const onDrop = (acceptedFiles) => {
    const updatedImages = [...selectedImages, ...acceptedFiles];
    setSelectedImages(updatedImages);
    setFieldValue(field.name, updatedImages);
  };

  const removeImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
    setFieldValue(field.name, updatedImages);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{
          border: "1px dotted black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <div className={`${Styles.selectedImages}`}>
        {selectedImages.map((file, index) => (
          <div key={index} className={`${Styles.selectedImage}`}>
            <img
              src={URL.createObjectURL(file)}
              alt={`Selected ${file.name}`}
              className={`${Styles.selectedImage}`}
            />
            <button
              type="button"
              className={`${Styles.button}`}
              onClick={() => removeImage(index)}
            >
              <FaRegTrashAlt
                style={{
                  color: "#ffffff",
                }}
              />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default FileUploadField;
