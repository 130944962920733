import React, { useState, useEffect } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import Style from "../../UserStyle.module.css";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Api from "../../components/api/Api";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import { useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";

import PostComment from "./PostComment";

const PostItem = () => {
  const { postId } = useParams();
  const [userDetail, setUserDetails] = useState();

  const loggedInUser = useSelector((state) => state.auth.user);
  // const userId = loggedInUser?.userDetails?.id;

  const [isLoading, setIsLoading] = useState(false);
  const getDetailsApi = Api({
    url: `admin/desiredRequest/${postId}`,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchData();
        console.log(responseData);
        setUserDetails(responseData?.data?.desiredRequest);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Notification />
          <Container style={{ marginTop: "2rem" }}>
            <SingleDetailHeader
              header="Post Details"
              description="See the post details section"
            />

            <Row>
              <Col>
                <SingleDetail
                  label="Name"
                  userDetail={userDetail?.user?.first_name}
                />
              </Col>

              <Col>
                <SingleDetail label="Title" userDetail={userDetail?.title} />
              </Col>
              <Col>
                <SingleDetail
                  label="Purpose Name"
                  userDetail={userDetail?.purpose?.name}
                />
              </Col>
            </Row>

            <Row>
              <Col className={Style.col} md={6}>
                <label className={Style.label}>Description</label>
                <textarea
                  className={Style.textarea1}
                  style={{ padding: "1rem 2rem" }}
                >
                  {userDetail?.description}
                </textarea>
              </Col>
              <Col md={6} style={{ marginTop: "1.5rem" }}>
                {" "}
                <h4>All City</h4>
                {userDetail?.cities.map((singleCity) => (
                  <ListGroup>
                    <ListGroup.Item>{singleCity?.name}</ListGroup.Item>
                  </ListGroup>
                ))}
              </Col>
            </Row>

            <PostComment />

            <div className={Style.btnCol}>
              <Button className={`btn btn-primary`}>
                Edit Contact Us Details
              </Button>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default PostItem;
