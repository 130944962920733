import React from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "../../common/Header";

const QueryRoot = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default QueryRoot;
