import { NavLink, React } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";
import classes from "./MainNavigation.module.css";
import NewsletterSignup from "../newsletter/NewsletterSignup";

function MainNavigation() {
  const loggedInUser = useSelector((state) => state.auth.user);

  return (
    <>
      <header className="main-header bg-light sticky-top">
        <div className="header-sticky sticky-top">
          <Navbar collapseOnSelect expand="lg" className="">
            <Container>
              <Navbar.Brand>
                <NavLink to="/">
                  <img src={Logo} alt="Logo" />
                </NavLink>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="main-nav-menu">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <NavLink to="/" className="nav-link">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/about-us" className="nav-link">
                        About us
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink to="/property-list" className="nav-link">
                        Property
                      </NavLink>
                    </li>

                    <li className="nav-item submenu">
                      <NavDropdown
                        title="Services"
                        id="collapsible-nav-dropdown"
                      >
                        <ul>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/interiordesign">
                              Interior Design
                            </NavLink>
                          </li>
                        </ul>
                        {/* <NavDropdown.Item href="/projects">
                      Profile
                    </NavDropdown.Item>

                    <NavDropdown.Item Link to="/projects">
                      Login
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item> */}
                      </NavDropdown>
                    </li>
                    <li className="nav-item">
                      <NavLink to="contactus" className="nav-link">
                        Contact us
                      </NavLink>
                    </li>
                    {loggedInUser?.statusType === "SUCCESS" ? (
                      <>
                        <NavLink to="/admin" className="nav-link login-text">
                          <i
                            className="fa fa-user-o mr-2"
                            aria-hidden="true"
                          ></i>
                          Dashboard
                        </NavLink>
                      </>
                    ) : (
                      <>
                        <li className="nav-item">
                          <NavLink to="/login" className="nav-link login-text">
                            <i class="fa fa-user-o" aria-hidden="true"></i>
                            Login
                          </NavLink>
                        </li>
                        <li className="nav-item highlighted-menu">
                          <NavLink to="/sign-up" className="nav-link">
                            Book Now
                          </NavLink>
                        </li>
                      </>
                    )}
                  </ul>
                </Nav>
                {/* <Nav>
                  <NavLink to="/login" className="nav-link">
                    Login
                  </NavLink>
                  <NavLink to="/register" className="btn btn-primary">
                    Register
                  </NavLink>
                </Nav> */}
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>

      {/* <header className={classes.header}>
        <nav>
          <ul className={classes.list}>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                end
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/events"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                Events
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/newsletter"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                Newsletter
              </NavLink>
            </li>
          </ul>
        </nav>
        <NewsletterSignup />
      </header> */}
    </>
  );
}

export default MainNavigation;
