import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import Api from "../../components/api/Api";
import Style from "../../UserStyle.module.css";

import Spinner from "react-bootstrap/Spinner";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import { Link } from "react-router-dom";
const UserProfile = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const [userDetail, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;

  const getDetailsApi = Api({
    url: `admin/user/id/${userId}`,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchData();
        console.log(responseData);
        setUserDetails(responseData?.data?.user);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container>
          <SingleDetailHeader
            header="User Profile"
            description="See the user profile section"
          />
          <Row className={Style.profileImgSection}>
            <h4>Profile Photo</h4>
            <img
              src="https://plus.unsplash.com/premium_photo-1711393509013-779e93b0da90?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzMnx8fGVufDB8fHx8fA%3D%3D"
              className={Style.profileImg}
            />
          </Row>
          <Row>
            {/* <Col>
              <SingleDetail
                label="User Name"
                userDetail={userDetail?.first_name}
              />
            </Col> */}
            <Col>
              <SingleDetail
                label="First Name"
                userDetail={userDetail?.first_name}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleDetail
                label="Last Name"
                userDetail={userDetail?.last_name}
              />
            </Col>
            <Col>
              <SingleDetail
                label="Email"
                userDetail={userDetail?.email_address}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleDetail
                label="Phone Number"
                userDetail={userDetail?.mobile_number}
              />
            </Col>
            <Col>
              <SingleDetail
                label="User Type"
                userDetail={userDetail?.userType.name}
              />
            </Col>
          </Row>
          <div className={Style.btnCol}>
            <Link className={`btn btn-primary`} to={`/user/edit-profile`}>
              Edit User Profile
            </Link>
          </div>
        </Container>
      )}
    </>
  );
};

export default UserProfile;
