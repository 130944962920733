import React, { useState, useEffect } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import Style from "../../UserStyle.module.css";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import Api from "../../components/api/Api";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";

const UserItem = () => {
  const { userId } = useParams();
  const [userDetail, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getDetailsApi = Api({
    url: `admin/user/id/${userId}`,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchData(true);

        setUserDetails(responseData.data.user);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container style={{ marginTop: "2rem" }}>
          <SingleDetailHeader
            header="User Details"
            description="See the user details section"
          />
          <Row className={Style.profileImgSection}>
            <h4>Profile Photo</h4>
            <img
              src="https://plus.unsplash.com/premium_photo-1711393509013-779e93b0da90?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzMnx8fGVufDB8fHx8fA%3D%3D"
              className={Style.profileImg}
            />
          </Row>
          <Row>
            <Col>
              <SingleDetail
                label="First Name"
                userDetail={userDetail?.first_name}
              />
            </Col>
            <Col>
              <SingleDetail
                label="Last Name"
                userDetail={userDetail?.last_name}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <SingleDetail
                label="Mobile Number"
                userDetail={userDetail?.mobile_number}
              />
            </Col>
            <Col>
              <SingleDetail
                label="Email Address"
                userDetail={userDetail?.email_address}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleDetail
                label="User Type"
                userDetail={userDetail?.userType?.name}
              />
            </Col>
          </Row>
          <div className={Style.btnCol}>
            <Button className={`btn btn-primary`}>Edit User Details</Button>
          </div>
        </Container>
      )}
    </>
  );
};

export default UserItem;
