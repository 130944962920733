import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";

import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import axios from "axios";
import EditPropertyImages from "./EditPropertyImages";

const EditProperty = () => {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth.user);
  const authToken = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const { propertyId } = useParams();

  const [formData, setFormData] = useState({});
  const [countries, setCountries] = useState([]);
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedImageNames, setUploadedImageNames] = useState([]);
  const [attachmentUploadNames, setAttachmentUploadNames] = useState(false);
  const [uploadedFilesNames, setUploadedFilesNames] = useState([]);
  const [uploadImagesSuccess, setUploadImagesSuccess] = useState(false);
  const [uploadAttachmentsSuccess, setUploadAttachmentsSuccess] =
    useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [textData, setTextData] = useState("Property Description");
  const [isStateError, setIsStateError] = useState(false);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [areaInput, setAreaInput] = useState("");
  const [areaSuggestions, setAreaSuggestions] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [createPropertyDisabled, setCreatePropertyDisabled] = useState(true);
  const [createPropertyClicked, setCreatePropertyClicked] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [fieldDescription, setFieldDescription] = useState("");
  const [fieldPrice, setFieldPrice] = useState(0);
  const [fieldPropertyType, setFieldPropertyType] = useState(0);
  const [fieldPurpose, setFieldPurpose] = useState(0);
  const [fieldStatus, setFieldStatus] = useState(0);
  const [fieldArea, setFieldArea] = useState("");
  const [fieldCountry, setFieldCountry] = useState(0);
  const [fieldState, setFieldState] = useState(0);
  const [fieldCity, setFieldCity] = useState(0);
  const [fieldAddress, setFieldAddress] = useState("");
  const [fieldPinCode, setFieldPinCode] = useState("");
  const [fieldFeaturedProperty, setFieldFeaturedProperty] = useState("");
  const [fieldBedRooms, setFieldBedRooms] = useState(0);
  const [fieldBathRooms, setFieldBathRooms] = useState(0);
  const [fieldRooms, setFieldRooms] = useState(0);
  const [fieldSwimmingPool, setFieldSwimmingPool] = useState(false);
  const [fieldGasConnection, setFieldGasConnection] = useState(false);
  const [fieldRo, setFieldRo] = useState(false);
  const [fieldClubHouse, setFieldClubHouse] = useState(false);
  const [fieldBasketBallCourt, setFieldBasketBallCourt] = useState(false);
  const [fieldTennisCourt, setFieldTennisCourt] = useState(false);
  const [fieldGym, setFieldGym] = useState(false);
  const [fieldIndoorGames, setFieldIndoorGames] = useState(false);
  const [fieldChildPlayArea, setFieldChildPlayArea] = useState(false);
  const [fieldHospitalNearBy, setFieldHospitalNearBy] = useState(false);
  const [fieldMallNearBy, setFieldMallNearBy] = useState(false);
  const [fieldMarketNearBy, setFieldMarketNearBy] = useState(false);
  const [fieldSchooldNearBy, setFieldSchooldNearBy] = useState(false);
  const [fieldVideoUrl, setFieldVideoUrl] = useState("");
  const [fieldTags, setFieldTags] = useState("");
  const [propertyDetails, setPropertyDetails] = useState([]);

  const getPropertyApi = Api({
    url: `property/propertyDetail/${propertyId}`,
  });
  const getCountryApi = Api({
    url: `admin/countries`,
  });
  const getPurposeApi = Api({
    url: `property/purposes`,
  });
  const getTypesApi = Api({
    url: `property/types`,
  });
  const getStatusApi = Api({
    url: `property/statusList`,
  });

  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const [propertyData, countryData, purposeData, typesData, statusData] =
          await Promise.all([
            getPropertyApi.fetchData(true),
            getCountryApi.fetchData(true),
            getPurposeApi.fetchData(true),
            getTypesApi.fetchData(true),
            getStatusApi.fetchData(true),
          ]);

        const data = await propertyData.data;
        console.log(data);
        if (data.statusType === "SUCCESS") {
          setPropertyDetails(data.propertyInfo);
          setFieldName(data.propertyInfo.name);
          setFieldDescription(data.propertyInfo.description);
          setFieldPrice(data.propertyInfo.price);
          setFieldPropertyType(data.propertyInfo.type.id);
          setFieldPurpose(data.propertyInfo.purpose.id);
          setFieldStatus(data.propertyInfo.status.id);
          setFieldArea(data.propertyInfo.area.name);
          setFieldCountry(data.propertyInfo.area.city.state.country.id);
          setFieldState(data.propertyInfo.area.city.state.id);
          setFieldCity(data.propertyInfo.area.city.id);
          setFieldAddress(data.propertyInfo.address);
          setFieldPinCode(data.propertyInfo.pin_code);
          setFieldFeaturedProperty(data.propertyInfo.featured_property);

          setFieldBedRooms(
            data.propertyInfo.structure.bed_rooms != null
              ? data.propertyInfo.structure.bed_rooms
              : 0
          );
          setFieldBathRooms(
            data.propertyInfo.structure.bath_rooms != null
              ? data.propertyInfo.structure.bath_rooms
              : 0
          );
          setFieldRooms(
            data.propertyInfo.structure.rooms != null
              ? data.propertyInfo.structure.rooms
              : 0
          );

          setFieldSwimmingPool(data.propertyInfo.feature.swimming_pool);
          setFieldGasConnection(data.propertyInfo.feature.gas_connection);
          setFieldRo(data.propertyInfo.feature.ro);
          setFieldClubHouse(data.propertyInfo.feature.club_house);
          setFieldBasketBallCourt(data.propertyInfo.feature.basketball_court);
          setFieldTennisCourt(data.propertyInfo.feature.tennis_court);
          setFieldGym(data.propertyInfo.feature.gym);
          setFieldIndoorGames(data.propertyInfo.feature.indoor_games);
          setFieldChildPlayArea(data.propertyInfo.feature.child_play_area);
          setFieldHospitalNearBy(data.propertyInfo.feature.hospital_near_by);
          setFieldMallNearBy(data.propertyInfo.feature.mall_near_by);
          setFieldMarketNearBy(data.propertyInfo.feature.market_near_by);
          setFieldSchooldNearBy(data.propertyInfo.feature.schoold_near_by);

          setFieldVideoUrl(data.propertyInfo.videos[0].url_path);
          setFieldTags(
            data.propertyInfo.tags != null ? data.propertyInfo.tags : ""
          );
          setIsLoading(false);
        } else {
          setIsLoading(false);
          showToast("Failed to fetch property details!", "error");
        }
      } catch (error) {
        setIsLoading(false);
        showToast("Failed to fetch property details!", "error");
      }
    };

    const fetchCountries = async () => {
      try {
        const countryData = await getCountryApi.fetchData(true);
        const data = countryData.data;
        if (data.statusType === "SUCCESS") {
          setCountries(data.countries);
        } else {
          setCountries([]);
        }
      } catch (error) {
        showToast("Failed to fetch countries!", "error");
      }
    };

    const fetchPropertyPurposes = async () => {
      try {
        const purposeData = await getPurposeApi.fetchData(true);
        const data = purposeData.data;
        if (data.statusType === "SUCCESS") {
          setPropertyPurposes(data.propertyPurposes);
        } else {
          setPropertyPurposes([]);
        }
      } catch (error) {
        showToast("Failed to fetch property purposes!", "error");
      }
    };

    const fetchPropertyTypes = async () => {
      try {
        const typesData = await getTypesApi.fetchData(true);
        const data = typesData.data;
        if (data.statusType === "SUCCESS") {
          setPropertyTypes(data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }
      } catch (error) {
        showToast("Failed to fetch property types!", "error");
      }
    };

    const fetchStatusList = async () => {
      try {
        const statusData = await getStatusApi.fetchData(true);
        const data = statusData.data;
        if (data.statusType === "SUCCESS") {
          setStatusList(data.propertyStatuss);
        } else {
          setStatusList([]);
        }
      } catch (error) {
        showToast("Failed to fetch status list!", "error");
      }
    };

    fetchPropertyData();
    fetchCountries();
    fetchPropertyPurposes();
    fetchPropertyTypes();
    fetchStatusList();
  }, []);

  // Function to handle country change
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedState("");
    setSelectedCity("");
    setFieldCountry(selectedOption.value);
    setIsLoading(true);
    Api({
      url: `admin/statesByCountry/${selectedOption.value}`,
    })
      .fetchData(true)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusType === "SUCCESS") {
          const options = res.data.states.map((state) => ({
            value: state.id,
            label: state.name,
          }));
          setStates(options);
        } else {
          showToast("Failed to fetch states!", "error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("Failed to fetch states!", "error");
      });
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const fetchStates = (countryId) => {
    if (!countryId) {
      return;
    }

    axios
      .get(`${baseUrl}/admin/states/${countryId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`, // assuming authToken is defined
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.statusType === "SUCCESS") {
          setStates(data.states);
        } else {
          console.error("Error fetching states data:", data.text);
          setStates([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching state list:", error);
        setStates([]);
      });
  };
  // Function to handle state change
  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedCity("");
    setFieldState(selectedOption.value);
    setIsLoading(true);
    Api({
      url: `admin/citiesByState/${selectedOption.value}`,
    })
      .fetchData(true)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusType === "SUCCESS") {
          const options = res.data.cities.map((city) => ({
            value: city.id,
            label: city.name,
          }));
          setCities(options);
        } else {
          showToast("Failed to fetch cities!", "error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("Failed to fetch cities!", "error");
      });
  };

  // Function to handle city change
  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    setFieldCity(selectedOption.value);
  };

  // Function to handle area change
  const handleAreaChange = (selectedOption) => {
    setFieldArea(selectedOption.label);
  };

  // Function to fetch area suggestions
  const fetchAreaSuggestions = async (inputValue) => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin/areaSuggestions/${inputValue}`
      );
      if (response.data.statusType === "SUCCESS") {
        const suggestions = response.data.areaSuggestions.map((area) => ({
          value: area.id,
          label: area.name,
        }));
        setAreaSuggestions(suggestions);
      } else {
        showToast("Failed to fetch area suggestions!", "error");
      }
    } catch (error) {
      showToast("Failed to fetch area suggestions!", "error");
    }
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setCreatePropertyClicked(true);
    // Add form submission logic here
  };

  const handleSaveEditChanges = (e) => {
    e.preventDefault();
    const editedPropertyData = {
      id: +propertyId,
      user: {
        id: +userId,
      },
      city: {
        id: +fieldCity,
      },
      state: {
        id: +fieldState,
      },
      country: {
        id: +fieldCountry,
      },
      area: {
        id: 1,
        name: "Noida",
        description: "Noida Area",
      },
      type: {
        id: +fieldPropertyType,
      },
      purpose: {
        id: +fieldPurpose,
      },
      status: {
        id: +fieldStatus,
      },
      name: fieldName,
      description: textData,
      price: fieldPrice,

      address: fieldAddress,
      pin_code: fieldPinCode,
      structure: {
        bed_rooms: +fieldBedRooms,
        bath_rooms: +fieldBathRooms,
        rooms: +fieldRooms,
      },
      feature: {
        swimming_pool: fieldSwimmingPool,
        gas_connection: fieldGasConnection,
        ro: fieldRo,
        club_house: fieldClubHouse,
        basketball_court: fieldBasketBallCourt,
        tennis_court: fieldTennisCourt,
        gym: fieldGym,
        indoor_games: fieldIndoorGames,
        child_play_area: fieldChildPlayArea,
        hospital_near_by: fieldHospitalNearBy,
        mall_near_by: fieldMallNearBy,
        schoold_near_by: fieldSchooldNearBy,
        market_near_by: fieldMarketNearBy,
      },
      tags: fieldTags,
    };

    console.log(editedPropertyData);
    axios
      .post(`${baseUrl}/property/editProperty`, editedPropertyData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.statusType === "SUCCESS") {
          showToast("Property Edited Successfully", {
            type: "success",
          });

          setTimeout(() => {
            navigate("/property");
          }, 3000);
        } else {
          console.error("Error editing property:", response.data.text);
        }
      })
      .catch((error) => {
        console.error("Error editing property:", error);
      });
  };

  return (
    <>
      <Notification />
      <Container>
        <SingleDetailHeader
          header="Edit Property Details"
          description="Edit Property details section"
        />
        <Row className="justify-content-md-center">
          <Col xs={12} md={8}>
            <Form onSubmit={(e) => handleSaveEditChanges(e)}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={fieldName}
                      onChange={(e) => setFieldName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formPrice">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      name="price"
                      value={fieldPrice}
                      onChange={(e) => setFieldPrice(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formPropertyType">
                    <Form.Label>Property Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="propertyType"
                      value={fieldPropertyType}
                      onChange={(e) => setFieldPropertyType(e.target.value)}
                      required
                    >
                      {propertyTypes.map((propertyType) => (
                        <option key={propertyType.id} value={propertyType.id}>
                          {propertyType.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-3">
                  <div className="ckeditor-component">
                    <CKEditor
                      editor={ClassicEditor}
                      data={fieldDescription}
                      required
                      onChange={(event, editor) =>
                        setTextData(editor.getData())
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formPropertyType">
                    <Form.Label>Property Purposes</Form.Label>
                    {/* propertyPurposes */}
                    <Form.Control
                      as="select"
                      value={fieldPurpose}
                      onChange={(e) => setFieldPurpose(e.target.value)}
                      name="purpose"
                      required
                    >
                      {/* Add options for property type */}
                      {propertyPurposes.map((purpose) => (
                        <option key={purpose.id} value={purpose.id}>
                          {purpose.name}
                        </option>
                      ))}
                      {/* Add more options as needed */}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formPropertyType">
                    <Form.Label>Status</Form.Label>
                    {/* propertyPurposes */}
                    <Form.Control
                      as="select"
                      value={fieldStatus}
                      required
                      onChange={(e) => setFieldStatus(e.target.value)}
                      name="status"
                    >
                      {/* Add options for property type */}
                      {statusList && statusList.length > 0
                        ? statusList.map((status) => (
                            <option key={status.id} value={status.id}>
                              {status.name}
                            </option>
                          ))
                        : ""}
                      {/* Add more options as needed */}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Area</Form.Label>
                    <Form.Control
                      type="text"
                      value={fieldArea}
                      onChange={(e) => {
                        setFieldArea(e.target.value);
                        fetchAreaSuggestions(e.target.value);
                      }}
                      list="areaSuggestions"
                      name="area"
                      required
                    />
                    {/* {areaSuggestions.map((suggestion) => (
                  <option key={suggestion.id} value={suggestion.name} />
                ))} */}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formPropertyType">
                    <Form.Label> Country</Form.Label>
                    <Form.Control
                      as="select"
                      value={fieldCountry}
                      onChange={(e) => handleCountryChange}
                      name="country"
                      required
                    >
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formPropertyType">
                    <Form.Label> State</Form.Label>
                    <Form.Control
                      as="select"
                      value={fieldState}
                      onChange={handleStateChange}
                      disabled={!selectedCountry}
                      name="state"
                      required
                    >
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  {isStateError && (
                    <p className="error-message">
                      Please select a country first.
                    </p>
                  )}
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formPropertyType">
                    <Form.Label> City</Form.Label>
                    <Form.Control
                      as="select"
                      value={fieldCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      disabled={isCityDisabled}
                      name="city"
                      required
                    >
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      className="input-field"
                      value={fieldAddress}
                      onChange={(e) => setFieldAddress(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Zipcode</Form.Label>
                    <Form.Control
                      type="text"
                      name="pin_code"
                      className="input-field"
                      value={fieldPinCode}
                      onChange={(e) => setFieldPinCode(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Bedrooms</Form.Label>
                    <Form.Control
                      type="number"
                      name="bed_rooms"
                      value={fieldBedRooms}
                      onChange={(e) => setFieldBedRooms(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* <Form.Group className="mb-3" controlId="formTitle">
                <Form.Label> Featured Property</Form.Label>
                <Form.Control
                  type="select"
                  name="featuredProperty"
                  value={fieldFeaturedProperty}
                  onChange={(e) => setFieldFeaturedProperty(e.target.value)}
                  required
                >
                  <option value="false" selected>
                    No
                  </option>
                  <option value="true">Yes</option>
                </Form.Control>
              </Form.Group> */}

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Bathrooms</Form.Label>
                    <Form.Control
                      type="number"
                      name="bath_rooms"
                      value={fieldBathRooms}
                      onChange={(e) => setFieldBathRooms(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Rooms</Form.Label>
                    <Form.Control
                      type="number"
                      name="rooms"
                      value={fieldRooms}
                      onChange={(e) => setFieldRooms(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label> Each tag separated by comma ,</Form.Label>
                    <Form.Control
                      type="text"
                      name="tags"
                      required
                      value={fieldTags}
                      onChange={(e) => setFieldTags(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Swimming Pool"
                    name="swimming_pool"
                    checked={fieldSwimmingPool}
                    onChange={(e) => setFieldSwimmingPool(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Gas Connection"
                    name="gas_connection"
                    checked={fieldGasConnection}
                    onChange={(e) => setFieldGasConnection(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="RO"
                    name="ro"
                    checked={fieldRo}
                    onChange={(e) => setFieldRo(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Club House"
                    name="club_house"
                    checked={fieldClubHouse}
                    onChange={(e) => setFieldClubHouse(e.target.checked)}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Basketball Court"
                    name="basketball_court"
                    checked={fieldBasketBallCourt}
                    onChange={(e) => setFieldBasketBallCourt(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Tennis Court"
                    name="tennis_court"
                    checked={fieldTennisCourt}
                    onChange={(e) => setFieldTennisCourt(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Gym"
                    name="gym"
                    checked={fieldGym}
                    onChange={(e) => setFieldGym(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Indoor Games"
                    name="indoor_games"
                    checked={fieldIndoorGames}
                    onChange={(e) => setFieldIndoorGames(e.target.checked)}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Child Play Area"
                    name="child_play_area"
                    checked={fieldChildPlayArea}
                    onChange={(e) => setFieldChildPlayArea(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Hospital Near By"
                    name="hospital_near_by"
                    checked={fieldHospitalNearBy}
                    onChange={(e) => setFieldHospitalNearBy(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Mall Near By"
                    name="mall_near_by"
                    checked={fieldMallNearBy}
                    onChange={(e) => setFieldMallNearBy(e.target.checked)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Market Near By"
                    name="market_near_by"
                    checked={fieldMarketNearBy}
                    onChange={(e) => setFieldMarketNearBy(e.target.checked)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="School Near By"
                    name="schoold_near_by"
                    checked={fieldSchooldNearBy}
                    onChange={(e) => setFieldSchooldNearBy(e.target.checked)}
                  />
                </Col>
              </Row>

              <Button variant="primary" type="submit" className="mt-3 mb-3">
                Edit Property
              </Button>
            </Form>
            <Row className="mt-5">
              <h3>Edit Images</h3>
              <EditPropertyImages imageData={propertyDetails} />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditProperty;
