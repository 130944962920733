import React from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
const BreadCrumb = ({ data }) => {
  return (
    <>
      <Row>
        <div className="page-titles">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="javascript:void(0)">{data}</Link>
            </li>
          </ol>
        </div>
      </Row>
    </>
  );
};

export default BreadCrumb;
