import React, { useState, useEffect } from "react";
import Api from "../../../admin/components/api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/spinner/Spinner";
import SingleDetailHeader from "../../../admin/components/singleDetail/SingleDetailHeader";
import axios from "axios";

const AddContact = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [allPurpose, setAllPurpose] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState({});
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const addPostApi = Api({
    url: `user/contact/add`,
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    address: "",
    address1: "",
    street: "",
    apartment: "",
    zip_code: "",
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await axios.get(`${baseUrl}/admin/countries`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          console.log("Your Country id is", countryId);
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.get(
              `${baseUrl}/admin/states/${countryId}`,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          console.log("your stateid is ", stateId);
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.get(
              `${baseUrl}/admin/cities/${stateId}`,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedCountry, selectedState, loggedInUser?.token]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      console.log(selectedState);
      const state = await states.filter((data) => data.id === +selectedState);
      const city = await cities.filter((data) => data.id === +selectedState);
      console.log(state);
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_number: values.mobile_number,
        email_address: values.email_address,
        address: values.address,
        address1: values.address1,
        street: values.street,
        apartment: values.apartment,
        country_name: values.country,
        state_name: state[0].name,
        city_name: values.city,
        zip_code: values.zip_code,
      };

      console.log(data);
      const responseData = await addPostApi.postData(data);
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Post added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/post");
        }, 3000);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Notification />
      <Container style={{ marginTop: "2rem" }}>
        <SingleDetailHeader
          header="Add Contact"
          description="Add the contact details section"
        />
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Row>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="first_name" className="form-label">
                      First Name
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="first_name"
                      aria-describedby="emailHelp"
                      placeholder="Enter First Name"
                      name="first_name"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <Field
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      name="email_address"
                    />
                    <ErrorMessage
                      name="email_address"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="last_name" className="form-label">
                      Last Name
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="last_name"
                      aria-describedby="emailHelp"
                      placeholder="Enter Last Name"
                      name="last_name"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="last_name"
                      aria-describedby="emailHelp"
                      placeholder="Enter Address"
                      name="address"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="address1" className="form-label">
                      Address 1
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="address1"
                      aria-describedby="emailHelp"
                      placeholder="Enter Address"
                      name="address1"
                    />
                    <ErrorMessage
                      name="address1"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="mobile_number" className="form-label">
                      Mobile Number
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="mobile_number"
                      aria-describedby="emailHelp"
                      placeholder="Enter Phone Number"
                      name="mobile_number"
                    />
                    <ErrorMessage
                      name="mobile_number"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="zip_code" className="form-label">
                      Zip Code
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="zip_code"
                      aria-describedby="emailHelp"
                      placeholder="Enter Zip Code"
                      name="zip_code"
                    />
                    <ErrorMessage
                      name="zip_code"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="apartment" className="form-label">
                      Apartment
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="apartment"
                      aria-describedby="emailHelp"
                      placeholder="Enter Apartment Name"
                      name="apartment"
                    />
                    <ErrorMessage
                      name="apartment"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="street" className="form-label">
                      Street
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="street"
                      aria-describedby="emailHelp"
                      placeholder="Enter Street"
                      name="street"
                    />
                    <ErrorMessage
                      name="street"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <div className="">
                    <label htmlFor="title" className="form-label">
                      Country
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="country"
                      required=""
                    >
                      <option value="">-- Select Country --</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="">
                    <label htmlFor="title" className="form-label">
                      State
                    </label>
                    <select
                      className="form-control"
                      name="state"
                      required=""
                      onChange={(e) => setSelectedState(e.target.value)}
                    >
                      <option value="">-- Select State --</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="">
                    <label htmlFor="title" className="form-label">
                      City
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="city"
                      required=""
                    >
                      <option value="">-- Select City --</option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                </Col>
              </Row>

              <button
                type="submit"
                className="btn btn-primary mt-5"
                style={{ marginBottom: "10rem" }}
                disabled={isSubmitting}
                // disabled={true}
              >
                {isSubmitting ? <Spinner loading={true} /> : "Add Contact "}
              </button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default AddContact;
