import React, { useState, CSSProperties } from "react";
// import { useState, CSSProperties } from "react";

import BeatLoader from "react-spinners/BeatLoader";

const override = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // height: "100vh",
  margin: "0 auto",
  borderColor: "black",
};
const Spinner = ({ loading }) => {
  return <BeatLoader color="#000" cssOverride={override} loading={loading}/>;
};

export default Spinner;
