import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
const Query = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const propertyApi = Api({
    url: "admin/desiredQueries",
  });
  const deleteQueryApi = Api({
    url: "admin/deleteDesiredQuery",
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin/deleteDesiredQuery/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Query deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);
  const columns = [
    { displayName: "S.No.", key: "id" },
    { displayName: "Name", key: "name" },
    { displayName: "Mobile Number", key: "mobile_number" },
    { displayName: "Email Address", key: "email_address" },
    { displayName: "Area", key: "area.name" },
  ];
  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const responseData = await propertyApi.fetchData();
        setData(responseData.data.desiredQueries);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPropertyData();
  }, []);
  return (
    <>
      <Notification />
      <div className="content-body">
        <Container>
          {/* Delete Alert */}
          <BreadCrumb data="All Query" />
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            <Col md={2}>
              <AdminHeader />
            </Col>
            <Col md={10}>
              {data.length === 0 ? (
                <AllMessage title="No Query Found" />
              ) : (
                <List
                  title="All Query"
                  data={data}
                  columns={columns}
                  route="query"
                  handleDelete={handleDelete}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Query;
