import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import Error from "../../../components/common/error/Error";
import Api from "../../components/api/Api";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
const AddUser = ({ userTypePartner }) => {
  const loggedInUser = useSelector((state) => state.auth);
  console.log(loggedInUser);
  console.log(userTypePartner);
  const userType = loggedInUser?.user?.userDetails?.userType;
  console.log(userType);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const initialValues = {
    user_name: "",
    first_name: "",
    last_name: "",
    email_address: "",
    mobile_number: "",
    userType: {},
    role: [],
  };

  const getDetailsApi = Api({
    url: `admin/addUser`,
  });
  const handleSubmit = async (values, { setSubmitting }) => {
    const roleValues = selectedRoles.map((option) => option.value);
    console.log(roleValues);
    console.log(values);

    try {
      setSubmitting(true);
      console.log(userTypePartner);
      const data = {
        user_name: values.user_name,
        first_name: values.first_name,
        last_name: values.last_name,
        email_address: values.email_address,
        mobile_number: values.mobile_number,
        // userType: selectedUserType ? selectedUserType.value : userType,

        role: roleValues,
      };

      console.log(data);
      const responseData = await getDetailsApi.postData(data);
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("User added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/user");
        }, 3000);
      }
      console.log(data);
    } catch (error) {
      setIsError(true);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  //   const validationSchema = Yup.object({
  //     first_name: Yup.string().required("First Name is required"),
  //     last_name: Yup.string().required("Last Name is required"),
  //     email_address: Yup.string()
  //       .email("Invalid email address")
  //       .required("Email address is required"),
  //     mobile_number: Yup.string()
  //       .matches(/^[6-9]\d{9}$/, "Invalid Indian phone number")
  //       .required("Mobile Number is required"),
  //     password: Yup.string().required("Password is required"),
  //   });

  const rolesOptions = [
    { value: "ROLE_ADMIN", label: "Admin" },
    { value: "ROLE_LEAD", label: "Lead" },
    { value: "ROLE_TEAM_MEMBER", label: "Team Member" },
    { value: "ROLE_FRONT_DESK", label: "Front Desk" },
    { value: "ROLE_USER", label: "User" },
  ];

  const userTypesOptions = [
    { value: { name: "ALL_HOME_DESIRE" }, label: "All Home Desire" },
    { value: { name: "PARTNER" }, label: "Partner" },
    { value: { name: "CUSTOMER" }, label: "Customer" },
  ];

  return (
    <Container style={{ marginTop: "2rem" }}>
      <SingleDetailHeader header="Add" description="Add  details section" />
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row>
              <Col>
                <div className="mb-3">
                  <label htmlFor="user_name" className="form-label">
                    User Name
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="user_name"
                    aria-describedby="emailHelp"
                    placeholder="Enter User Name"
                    name="user_name"
                  />
                  <ErrorMessage
                    name="user_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label htmlFor="first_name" className="form-label">
                    First Name
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="first_name"
                    aria-describedby="emailHelp"
                    placeholder="Enter First Name"
                    name="first_name"
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label htmlFor="last_name" className="form-label">
                    Last Name
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="last_name"
                    aria-describedby="emailHelp"
                    placeholder="Enter Last Name"
                    name="last_name"
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <Field
                    type="email"
                    className="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    name="email_address"
                  />
                  <ErrorMessage
                    name="email_address"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label htmlFor="mobile_number" className="form-label">
                    Mobile Number
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="mobile_number"
                    aria-describedby="emailHelp"
                    placeholder="Enter Phone Number"
                    name="mobile_number"
                  />
                  <ErrorMessage
                    name="mobile_number"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
            </Row>

            {userType === "SUPER_ADMIN" ? (
              <div className="mb-3">
                <label htmlFor="mobile_number" className="form-label">
                  User Type
                </label>
                <Select
                  options={userTypesOptions}
                  onChange={setSelectedUserType}
                  value={selectedUserType}
                  placeholder="Select User Type"
                />
                <ErrorMessage
                  name="userType"
                  component="div"
                  className="text-danger"
                />
              </div>
            ) : (
              <></>
            )}

            <div className="mb-3">
              <label htmlFor="mobile_number" className="form-label">
                Role
              </label>
              <Select
                options={rolesOptions}
                isMulti
                onChange={setSelectedRoles}
                value={selectedRoles}
                placeholder="Select Role"
              />
              <ErrorMessage
                name="role"
                component="div"
                className="text-danger"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary mt-5 mb-5"
              disabled={isSubmitting}
              // disabled={true}
            >
              {isSubmitting ? <Spinner loading={true} /> : "Add User"}
            </button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AddUser;
