import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";
import Col from "react-bootstrap/Col";

import cityImg1 from "../../assets/images/city-1.jpg";
import cityImg2 from "../../assets/images/city-2.jpg";
import cityImg3 from "../../assets/images/city-3.jpg";
import cityImg4 from "../../assets/images/city-4.jpg";
import PbcCard from "./cards/pbc/index";

const PropertyByCity = () => {
  return (
    <>
      <section className="property-by-city">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">
                <h3 className="wow fadeInUp">Location</h3>
                <h2 className="text-anime">Properties by Cities</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <PbcCard
                title="Greater Noida"
                properties="22 Properties"
                imageUrl={cityImg1}
              />
            </Col>
            <Col md={6} lg={3}>
              <PbcCard
                title="Greater Noida West"
                properties="22 Properties"
                imageUrl={cityImg2}
              />
            </Col>
            <Col md={6} lg={3}>
              <PbcCard
                title="Noida"
                properties="22 Properties"
                imageUrl={cityImg3}
              />
            </Col>
            <Col md={6} lg={3}>
              <PbcCard
                title="Meerut"
                properties="22 Properties"
                imageUrl={cityImg4}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PropertyByCity;
