import React from "react";
import IconImg1 from "../../assets/images/icon-counter-1.svg";
import IconImg2 from "../../assets/images/icon-counter-2.svg";
import IconImg3 from "../../assets/images/icon-counter-3.svg";
import IconImg4 from "../../assets/images/icon-counter-4.svg";

const StatCounter = () => {
  return (
    <div className="stat-counter">
      <div className="container">
        <div className="row">
          <CounterItem
            iconSrc={IconImg1}
            counterNumber={75}
            description="Interior Sketch"
            paragraph="All kinds of buildings in schematic or working design"
          />
          <CounterItem
            iconSrc={IconImg2}
            counterNumber={50}
            description="3d Modeling"
            paragraph="Studio provides a full range of 3D interior modeling"
          />
          <CounterItem
            iconSrc={IconImg3}
            counterNumber={66}
            description="Room Measurement"
            paragraph="Development of iperfect design of the project"
          />
          <CounterItem
            iconSrc={IconImg4}
            counterNumber={85}
            description="2d Planning"
            paragraph="We provide 2D planning for great visualization"
          />
        </div>
      </div>
    </div>
  );
};

const CounterItem = ({
  iconSrc,
  counterNumber,
  description,
  paragraph,
  delay,
}) => {
  return (
    <div className="col-lg-3 col-md-6">
      <div
        className="counter-item wow fadeInUp"
        style={{ visibility: "visible", animationDelay: delay }}
      >
        <div className="icon-box">
          <img src={iconSrc} alt="" />
        </div>
        <h3>
          <span className="counter">{counterNumber}</span> %
        </h3>
        <h2 class="card-title">{description}</h2>
        <p class="card-text fs-sm">{paragraph}</p>
      </div>
    </div>
  );
};

export default StatCounter;
