import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import UserMenu from "../../vendor/common/Header";

export const AdminHeader = () => {
  const loggedInUser = useSelector((state) => state.auth);
  console.log(loggedInUser);
  const userRoles = loggedInUser?.user?.userDetails?.roles;
  const userType = loggedInUser?.user?.userDetails?.userType;
  console.log(userType);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const logoutHandler = async () => {
    try {
      dispatch(logout());

      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };
  const navLinks = [
    { to: "/admin", label: "Dashboard" },
    {
      to: "/user",
      label: "User Management",
      allowed: ["SUPER_ADMIN", "ADMIN"],
    },
    { to: "/property", label: "Properties" },
    { to: "/query", label: "Queries", allowed: ["SUPER_ADMIN", "ADMIN"] },
    {
      to: "/contact-us",
      label: "Contact Us",
      allowed: ["SUPER_ADMIN", "ADMIN"],
    },
    { to: "/post", label: "Posts" },
    { to: "/user/profile", label: "Profile" },
  ];
  return (
    <>
      <Container>
        <Row>
          {/* Sidebar */}
          <nav
            id="sidebarMenu"
            className="col-md-3 col-lg-12 d-md-block bg-light sidebar collapse"
          >
            <div className="position-sticky">
              <ul className="nav flex-column">
                {navLinks.map((link, index) =>
                  (link.allowed && link.allowed.includes(userType)) ||
                  !link.allowed ? (
                    <li className="nav-item" key={index}>
                      <Link className="nav-link" to={link.to}>
                        {link.label}
                      </Link>
                    </li>
                  ) : null
                )}
                {userType === "PARTNER" && (
                  <UserMenu userType={userType} userRoles={userRoles} />
                )}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    onClick={logoutHandler}
                    style={{ cursor: "pointer" }}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* Sidebar */}
        </Row>
      </Container>
    </>
  );
};
export default AdminHeader;
