import styled from "styled-components";

export const StyledAddedTodayCard = styled.div`
  border-radius: calc(0.75rem - 1px);
  overflow: hidden;
  position: relative;

  .location-image img {
    width: 100%;
    transition: all 0.5s ease-out;
  }

  .location-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    background: var(--overlay-color);
  }

  .location-content .location-header h3 {
    font-size: 22px;
    font-weight: 500;
    color: var(--white-color);
  }

  .location-content .location-header p {
    color: var(--white-color);
    font-size: 16px;
  }

  .location-content .location-footer {
    position: absolute;
    left: 30px;
    bottom: 10px;
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  &:hover .location-content .location-footer {
    bottom: 25px;
    opacity: 1;
  }

  &:hover .location-image img {
    transform: scale(1.2);
  }
`;
