import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";
import Col from "react-bootstrap/Col";

import BuyImg from "../../assets/images/buy.png";
import SellImg from "../../assets/images/sell.png";
import RentImg from "../../assets/images/rent.png";
import Button from "../common/button/Button";
import TypeCard from "./cards/typeCard/TypeCard";

const Type = () => {
  return (
    <>
      <section className="all-section">
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <TypeCard
                title="Buy a property"
                description="Explore a variety of properties available for purchase in your desired location. Whether you're looking for a cozy apartment, a spacious house, or a commercial space, find the perfect property to call your own."
                imageUrl={BuyImg}
                btnText="Search Property"
              />
            </Col>
            <Col lg={4} md={6}>
              <TypeCard
                title="Sell a property"
                description="Ready to sell your property? List it with us and reach a wide audience of potential buyers. Our platform offers tools and resources to help you showcase your property effectively and find the right buyer quickly."
                imageUrl={SellImg}
                btnText="List Property"
              />
            </Col>
            <Col lg={4} md={6}>
              <TypeCard
                title="Rent a property"
                description="Discover a range of rental properties suited to your lifestyle and budget. Whether you're searching for a temporary residence or a long-term rental, find the perfect place to call home without the hassle."
                imageUrl={RentImg}
                btnText="Search Property"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Type;
