import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Nav, Button } from "react-bootstrap";
import PropertyImg4 from "../assets/images/property-4.jpg";
import Offer from "../components/homepage/Offer";
import Type from "../components/homepage/Type";
import AboutUs from "../components/homepage/AboutUs";
import AboutUsTwo from "../components/homepage/AboutUs2";
import AddedToday from "../components/homepage/AddedToday";
import HowItWorks from "../components/homepage/HowItWorks";
import InfoBar from "../components/homepage/InfoBar";
import cityImg1 from "../assets/images/city-1.jpg";
import cityImg2 from "../assets/images/city-2.jpg";
import cityImg3 from "../assets/images/city-3.jpg";
import cityImg4 from "../assets/images/city-4.jpg";
import IconAbout1 from "../assets/images/icon-about-1.svg";
import IconAbout2 from "../assets/images/icon-about-2.svg";
import IconAbout3 from "../assets/images/icon-about-3.svg";
import IconAbout4 from "../assets/images/icon-about-4.svg";
import RightContImg from "../assets/images/intecontact.png";

import StatCounter from "../components/homepage/StatCounter";
import Notification, {
  showToast,
} from "../components/common/notification/Notification";
import axios from "axios";
function InteriorDesign() {
  const [formData, setFormData] = useState({
    name: "",
    mobile_number: "",
    email_address: "",

    city: {
      name: "",
    },
    area: {
      name: "",
    },
    service: {
      name: "",
    },
    query: "",
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleNestedInputChange = (field, event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], name: value },
    }));
  };
  const submitForm = async (event) => {
    event.preventDefault();

    const response = await axios.post(
      `${baseUrl}/all/addDesiredQuery`,
      formData
    );
    console.log(response);
    // const
    if (response.data.statusType === "SUCCESS") {
      showToast("Query sent successfully", {
        type: "success",
      });
    }

    // Optionally, you can reset the form after submission
    setFormData({
      name: "",
      mobile_number: "",
      email_address: "",
      service: "Buy",
      city: "Laxmi Nagar",
      area: "",
      query: "",
    });
  };
  return (
    <>
      <Notification />
      <div className="home-page">
        <section className="page-header parallaxie">
          <Container>
            <Row>
              <Col>
                <div className="page-header-box">
                  <h1 className="text-anime">Bringing Life</h1>
                  <nav className="wow fadeInUp">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Bringing Life To Interior
                      </li>
                    </ol>
                  </nav>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="property-by-city">
          <Container>
            <Row>
              <Col md={12}>
                <div className="section-title">
                  <h3 className="">Bringing Life To Interior</h3>
                  <h2 className="text-anime">Properties by Interior</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={3}>
                <div className="location-item">
                  <div className="location-image">
                    <figure>
                      <img src={cityImg1} alt="" />
                    </figure>
                  </div>
                  <div className="location-content">
                    <div className="location-header">
                      <h3>Office Interior</h3>
                      <p>22 Properties</p>
                    </div>

                    <div className="location-footer">
                      <Link to="#" className="btn-default">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="location-item">
                  <div className="location-image">
                    <figure>
                      <img src={cityImg2} alt="" />
                    </figure>
                  </div>
                  <div className="location-content">
                    <div className="location-header">
                      <h3>Home Interior</h3>
                      <p>22 Properties</p>
                    </div>

                    <div className="location-footer">
                      <Link to="#" className="btn-default">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="location-item">
                  <div className="location-image">
                    <figure>
                      <img src={cityImg3} alt="" />
                    </figure>
                  </div>
                  <div className="location-content">
                    <div className="location-header">
                      <h3>Commercial Interior</h3>
                      <p>22 Properties</p>
                    </div>

                    <div className="location-footer">
                      <Link to="#" className="btn-default">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="location-item">
                  <div className="location-image">
                    <figure>
                      <img src={cityImg4} alt="" />
                    </figure>
                  </div>
                  <div className="location-content">
                    <div className="location-header">
                      <h3>Jakarta</h3>
                      <p>22 Properties</p>
                    </div>

                    <div className="location-footer">
                      <Link to="#" className="btn-default">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="why-choose-us no-bg">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} orderLg={2}>
                <div className="why-choose-us-image">
                  <figure className="reveal image-anime">
                    <img src={PropertyImg4} alt="" />
                  </figure>
                </div>
              </Col>
              <Col lg={6} orderLg={1}>
                <div className="why-choose-us-content">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">Our Benefits </h3>
                    <h2 className="text-anime">
                      Provide services to fulfill customers desire in terms of
                      property buy, sell & rent
                    </h2>
                  </div>

                  <div className="why-bosy">
                    <div className="about-content-body">
                      <ul>
                        <li>
                          <div className="icon-box">
                            <img src={IconAbout1} alt="" />
                          </div>
                          <span>
                            High quality interior design with low cost
                          </span>
                        </li>
                        <li>
                          <div className="icon-box">
                            <img src={IconAbout2} alt="" />
                          </div>
                          <span>
                            Dedicated interior architect align with customer
                          </span>
                        </li>
                        <li>
                          <div className="icon-box">
                            <img src={IconAbout3} alt="" />
                          </div>
                          <span>Full support after completion of work</span>
                        </li>
                        <li>
                          <div className="icon-box">
                            <img src={IconAbout4} alt="" />
                          </div>
                          <span>Experienced team</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <StatCounter />
        <section className="why-choose-us no-bg">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} orderLg={1}>
                <div className="why-choose-us-content">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">Share your query</h3>
                    <h2 className="text-anime">
                      Share your query with us, we will accomplish hassle-free
                    </h2>
                  </div>

                  <div className="why-bosy p-3">
                    <div className="property-inquiry-form">
                      <form onSubmit={submitForm} className="wpcf7">
                        <div className="main-form">
                          <div className="form-group mb-3">
                            <label className="mb-1">Name</label>
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              className="form-control"
                              aria-invalid="false"
                              placeholder="Name *"
                              required=""
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1">Mobile No</label>
                            <input
                              type="text"
                              name="mobile_number"
                              value={formData.mobile_number}
                              onChange={handleInputChange}
                              className="form-control"
                              aria-invalid="false"
                              placeholder="Mobile No.*"
                              required=""
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1">Email</label>
                            <input
                              type="email"
                              name="email_address"
                              value={formData.email_address}
                              onChange={handleInputChange}
                              className="form-control"
                              aria-invalid="false"
                              placeholder="Email*"
                              required=""
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1">Services</label>
                            <select
                              name="service"
                              className="form-control"
                              value={formData.service.name}
                              onChange={(e) =>
                                handleNestedInputChange("service", e)
                              }
                            >
                              <option value="Buy">Buy</option>
                              <option value="Sell">Sell</option>
                              <option value="Rent">Rent</option>
                              <option value="Interior Design">
                                Interior Design
                              </option>
                            </select>
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1">City</label>
                            <select
                              name="city"
                              className="form-control"
                              value={formData.city.name}
                              onChange={(e) =>
                                handleNestedInputChange("city", e)
                              }
                            >
                              <option value="Laxmi Nagar">Laxmi Nagar</option>
                              <option value="Anand Vihar">Anand Vihar</option>
                              <option value="Gautam Buddha Nagar">
                                Gautam Buddha Nagar
                              </option>
                              <option value="Ghaziabad">Ghaziabad</option>
                            </select>
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1">Sector/Area</label>
                            <input
                              type="text"
                              name="area"
                              value={formData.area.name}
                              onChange={(e) =>
                                handleNestedInputChange("area", e)
                              }
                              className="form-control"
                              aria-invalid="false"
                              placeholder="Sector/Area*"
                              required=""
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1">Query</label>
                            <textarea
                              type="text"
                              name="query"
                              value={formData.query}
                              onChange={handleInputChange}
                              className="form-control"
                              aria-invalid="false"
                              placeholder="Query*"
                              required=""
                              spellCheck={false}
                            ></textarea>
                          </div>
                          <p>
                            <button
                              type="submit"
                              id="send"
                              className="btn-default disabled"
                            >
                              Query With Us
                            </button>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} orderLg={2}>
                <div className="why-choose-us-image">
                  <figure className="reveal image-anime">
                    <img src={RightContImg} alt="" />
                  </figure>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default InteriorDesign;
