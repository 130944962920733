import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import "swiper/css";
import Col from "react-bootstrap/Col";
import badroom from "../../assets/images/icon-badroom-dark.svg";
import bathroom from "../../assets/images/icon-bathroom.svg";
import area from "../../assets/images/icon-area.svg";
import garage from "../../assets/images/icon-garage.svg";
import Button from "../common/button/Button";
import Spinner from "react-bootstrap/Spinner";
import Api from "../../admin/components/api/Api";
import axios from "axios";
import countryIcon from "../../assets/images/country.png";
import rupeeIcon from "../../assets/images/rupee-indian.png";
import OfferCard from "../homepage/cards/offerCard/OfferCard";
const PropertiesByCity = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allProperty, setAllProperty] = useState([]);
  const { cityname } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${baseUrl}/all/property/latestList`);
      console.log(response);

      if (response.data.statusType === "SUCCESS") {
        const properties = response.data.properties;
        // Filter properties based on city name
        const filteredProperties = properties.filter(
          (property) =>
            property?.area?.city?.name.toLowerCase() === cityname.toLowerCase()
        );
        setAllProperty(filteredProperties);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    try {
      setIsLoading(true);
      fetchData();

      setIsLoading(false);
    } catch (error) {}
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <section className="all-section">
          <Container>
            <Row>
              <div className="d-flex align-items-center justify-content-between mb-3 title-head-main">
                <h2 className="text-head-title">
                  properties in {cityname.toUpperCase()}
                </h2>
              </div>
            </Row>
            <Row>
              {allProperty.map((singleProperty) => (
                <Col lg={3} md={6} className="p-2">
                  <OfferCard
                    title={singleProperty?.name}
                    description={singleProperty?.description}
                    imageUrl={singleProperty?.images[0]?.image_url}
                    btnText="See"
                    type={singleProperty?.type?.name}
                    badroom={badroom}
                    bedroomsSize={singleProperty?.structure?.bed_rooms}
                    bathroom={bathroom}
                    bathroomSize={singleProperty?.structure?.bath_rooms}
                    area={area}
                    areaSize={singleProperty.area.city.name}
                    countryIcon={countryIcon}
                    country={singleProperty?.area?.city?.state?.country?.name}
                    price={singleProperty.price}
                    rupee={rupeeIcon}
                    to={"property-details"}
                    id={singleProperty.id}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default PropertiesByCity;
