import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";

const AddPost = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [allPurpose, setAllPurpose] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState({});
  const getPurposesApi = Api({
    url: `property/purposes`,
  });
  const getCityApi = Api({
    url: `admin/cities`,
  });
  const addPostApi = Api({
    url: `admin/addDesiredRequest`,
  });
  const getPurposes = async () => {
    try {
      const responseData = await getPurposesApi.fetchData(true);
      if (responseData.data.statusType === "SUCCESS") {
        console.log(responseData);
        const purposeData = responseData.data.propertyPurposes.map(
          (purpose) => ({
            label: purpose.name,
            value: purpose.id,
          })
        );

        setAllPurpose(purposeData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCity = async () => {
    try {
      const responseData = await getCityApi.fetchData(true);
      if (responseData.data.statusType === "SUCCESS") {
        console.log(responseData);
        const citiesData = responseData.data.cities.map((city) => ({
          label: city.name,
          value: city.id,
        }));
        setAllCity(citiesData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const initialValues = {
    title: "",
    description: "",
  };

  useEffect(() => {
    getPurposes();
    getCity();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      console.log(values);
      console.log(selectedCity);
      console.log(selectedPurpose.value);

      const data = {
        title: values.title,
        description: values.description,
        purpose: {
          id: selectedPurpose.value,
        },
        user: {
          id: userId,
        },
        desired_cities: selectedCity.map((city) => ({
          id: city.value,
        })),
      };

      const responseData = await addPostApi.postData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Post added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/post");
        }, 3000);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Notification />
      <Container style={{ marginTop: "2rem" }}>
        <SingleDetailHeader
          header="Add Post"
          description="Add the post details section"
        />
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="title"
                  aria-describedby="emailHelp"
                  placeholder="Enter Title"
                  name="title"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="description"
                  aria-describedby="emailHelp"
                  placeholder="Enter Description"
                  name="description"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="wish" className="form-label">
                  I Wish TO
                </label>
                <Select
                  options={allPurpose}
                  onChange={setSelectedPurpose}
                  value={selectedPurpose}
                  placeholder="Select User Type"
                />
                <ErrorMessage
                  name="userType"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="mobile_number" className="form-label">
                  City
                </label>
                <Select
                  options={allCity}
                  isMulti
                  onChange={setSelectedCity}
                  value={selectedCity}
                  placeholder="Select City"
                />

                <ErrorMessage
                  name="role"
                  component="div"
                  className="text-danger"
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary mt-5"
                style={{ marginBottom: "10rem" }}
                disabled={isSubmitting}
                // disabled={true}
              >
                {isSubmitting ? <Spinner loading={true} /> : "Add User"}
              </button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default AddPost;
