import React, { useState, useEffect } from "react";
import { Col, Container, InputGroup, Row } from "react-bootstrap";
import Style from "../../UserStyle.module.css";
import { useParams } from "react-router-dom";

import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
const PostComment = () => {
  const { postId } = useParams();

  const [allComment, setAllComment] = useState([]);
  const [commentId, setCommentId] = useState("");

  const [comment, setComment] = useState("");
  const loggedInUser = useSelector((state) => state.auth.user);
  const userDetails = loggedInUser?.userDetails;
  console.log(userDetails);
  const userId = loggedInUser?.userDetails?.id;
  const userType = loggedInUser?.userDetails?.userType;
  const token = loggedInUser?.token;
  console.log(userType);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedComment, setEditedComment] = useState("");
  const getAllCommentDetailsApi = Api({
    url: `admin/requestComments/${userId}/${postId}`,
  });
  const addAllCommentDetailsApi = Api({
    url: `admin/addRequestComment`,
  });

  const fetchAllComment = async () => {
    try {
      const fetchData = await getAllCommentDetailsApi.fetchData();
      console.log("Your All Comments", fetchData);
      setAllComment(fetchData?.data.requestComments);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllComment();
  }, []);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleAddComment = async () => {
    const data = {
      comment: comment,
      user: {
        id: userId,
      },
      desired_request: {
        id: postId,
      },
    };
    const sendData = await addAllCommentDetailsApi.postData(data);

    setComment("");
    if (sendData.data.statusType === "SUCCESS") {
      showToast("Comment added successfully", {
        type: "success",
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };
  const deleteCommentDetailsApi = Api({
    url: `${commentId}`,
  });
  const deleteComment = async (id) => {
    const response = await axios.get(
      `http://services.allhomedesire.in:8080/admin/deleteRequestComment/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.data.statusType === "SUCCESS") {
      showToast("Comment deleted successfully", {
        type: "success",
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleEditComment = (commentId, initialComment) => {
    setEditingCommentId(commentId);
    setEditedComment(initialComment);
  };

  const handleSaveEditedComment = async () => {
    try {
      const data = {
        id: editingCommentId,
        comment: editedComment,
      };
      const response = await axios.post(
        `http://services.allhomedesire.in:8080/admin/editRequestComment`,
        data,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Edited comment:", response);
      if (response.data.statusType === "SUCCESS") {
        showToast("Comment edited successfully", {
          type: "success",
        });

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      // Clear the editing state
      setEditingCommentId(null);
      setEditedComment("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Row style={{ marginTop: "2rem" }} className={Style.cardSectionComplete}>
        <h1>Comments</h1>
        <Col md={12}>
          <div className={Style.commentCard}>
            {allComment?.map((singleComment) => (
              <div className={Style.loopingCommentSectionCard}>
                <div className={Style.firstSection}>
                  <div className={Style.profileSection}>
                    <img
                      src="https://images.unsplash.com/photo-1711384552467-543d338e775b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      className={Style.profileSectionImg}
                    />
                    <div className={Style.profileSectionDetail}>
                      <span className={Style.name}>
                        {singleComment.user.first_name}{" "}
                        {singleComment.user.last_name}
                      </span>
                      <small className={Style.purpose1}>
                        <TimeAgo timestamp={singleComment.created_on} />
                      </small>
                    </div>
                  </div>
                  <div className={Style.profileSectionLast}>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>

                      <Dropdown.Menu>
                        {(singleComment.user.id === userId ||
                          userType === "SUPER_ADMIN") && (
                          <>
                            <Dropdown.Item
                              onClick={() =>
                                handleEditComment(
                                  singleComment.id,
                                  singleComment.comment
                                )
                              }
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => deleteComment(singleComment.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className={Style.secondSection}>
                  <div className={Style.messageDescription}>
                    {editingCommentId === singleComment.id ? (
                      <div className="edit-comment-form">
                        <textarea
                          className={`${Style.textarea1}`}
                          value={editedComment}
                          onChange={(e) => setEditedComment(e.target.value)}
                        />
                        <button
                          className="btn btn-danger"
                          style={{ marginBottom: "1rem" }}
                          onClick={handleSaveEditedComment}
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      <p>{singleComment.comment}</p>
                    )}

                    {/* <p>" {singleComment.comment} "</p> */}
                  </div>
                  <div className={Style.messageDescriptionReply}>
                    <textarea
                      className={Style.textareaDescription}
                      placeholder="Reply"
                    ></textarea>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Col>
        <Col md={12}>
          <textarea
            className={Style.textarea1}
            placeholder="Add Comment"
            value={comment}
            onChange={handleCommentChange}
          ></textarea>
          <button
            className={`btn btn-primary ${Style.addCommentBtn}`}
            onClick={handleAddComment}
          >
            Add Comment
          </button>
        </Col>
      </Row>
    </>
  );
};

export default PostComment;
