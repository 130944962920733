import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
// import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/Custom.css";

// import EditEventPage from "./pages/EditEvent";
import ErrorPage from "./pages/Error";
// import EventDetailPage, {
//   loader as eventDetailLoader,
//   action as deleteEventAction,
// } from "./pages/EventDetail";
// import EventsPage, { loader as eventsLoader } from "./pages/Events";
// import EventsRootLayout from "./pages/EventsRoot";
import HomePage from "./pages/Home";
// import NewEventPage from "./pages/NewEvent";
import RootLayout from "./pages/Root";
// import { action as manipulateEventAction } from "./components/events/EventForm";
// import NewsletterPage, { action as newsletterAction } from "./pages/Newsletter";
import Footer from "./components/common/Footer";
import About from "./pages/About";
import AdminHome from "./admin/Home";
import Login from "./pages/Login";
import PropertyListingForm from "./admin/dashboard/property/PropertyList";
import SignUp from "./pages/SignUp";
import AddProperty from "./admin/dashboard/property/AddProperty";
import Protected from "./components/auth/Protected";
import Dashboard from "./admin/dashboard/homepage/Dashboard";
import User from "./admin/dashboard/user/User";
import Query from "./admin/dashboard/query/Query";
import Post from "./admin/dashboard/post/Post";
import ContactUs from "./admin/dashboard/contactUs/ContactUs";
import { useDispatch, useSelector } from "react-redux";
import UserItem from "./admin/dashboard/user/UserItem";
import UserRoot from "./admin/dashboard/user/UserRoot";
import QueryItem from "./admin/dashboard/query/QueryItem";
import QueryRoot from "./admin/dashboard/query/QueryRoot";
import ContactUsRoot from "./admin/dashboard/contactUs/ContactUsRoot";
import ContactUsItem from "./admin/dashboard/contactUs/ContactUsItem";
import PostItem from "./admin/dashboard/post/PostItem";
import PostRoot from "./admin/dashboard/post/PostRoot";
import PropertyRoot from "./admin/dashboard/property/PropertyRoot";
import PropertyItem from "./admin/dashboard/property/PropertyItem";
import PropertyListing from "./pages/PropertyListing";
import PropertyDetails from "./components/propertyDetails/PropertyDetails";
import AddUser from "./admin/dashboard/user/AddUser";
import AddPost from "./admin/dashboard/post/AddPost";
import EditUser from "./admin/dashboard/user/EditUser";
import EditQuery from "./admin/dashboard/query/EditQuery";
import EditPost from "./admin/dashboard/post/EditPost";
import EditProperty from "./admin/dashboard/property/EditProperty";
import ConatactUsFront from "./pages/ContactUs";
import ChangePassword from "./admin/dashboard/user/ChangePassword";
import UserProfile from "./admin/dashboard/userProfile/UserProfile";
import EditUserProfile from "./admin/dashboard/userProfile/EditUserProfile";
import PropertyByCity from "./components/homepage/PropertyByCity";
import PropertiesByCity from "./components/propertyByCity/PropertyByCity";
import InteriorDesign from "./pages/InteriorDesign";
import VendorAddUser from "./vendor/admin/user-management/AddUser";
import VendorUser from "./vendor/admin/user-management/User";
import ContactRoot from "./vendor/common/contact/ContactRoot";
import AddContact from "./vendor/common/contact/AddContact";
import VendorContactUs from "./vendor/common/contact/ContactUs";
import VendorContactUsItem from "./vendor/common/contact/ContactUsItem";
import EditContactUs from "./vendor/common/contact/EditContactUs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "about-us",
        element: <About />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "property-list",
        element: <PropertyListing />,
      },
      {
        path: "property/city/:cityname",
        element: <PropertiesByCity />,
      },
      {
        path: "contactus",
        element: <ConatactUsFront />,
      },
      {
        path: "admin",
        element: (
          <Protected>
            <Dashboard />
          </Protected>
        ),
      },
      {
        path: "interiordesign",
        element: <InteriorDesign />,
      },

      {
        path: "/add-property",
        element: (
          <Protected>
            <AddProperty />
          </Protected>
        ),
      },

      {
        path: "/user/profile",
        element: (
          <Protected>
            <UserProfile />
          </Protected>
        ),
      },
      {
        path: "/user/edit-profile",
        element: (
          <Protected>
            <EditUserProfile />
          </Protected>
        ),
      },
      {
        path: "/property-details/:id",
        element: <PropertyDetails />,
      },
      {
        path: "property",
        element: (
          <Protected>
            <PropertyRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <PropertyListingForm />,
          },
          {
            path: ":propertyId",
            id: "property-details",
            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <PropertyItem />
                  </Protected>
                ),
              },
              {
                path: "edit",
                element: <EditProperty />,
              },
            ],
          },
        ],
      },
      {
        path: "user",
        element: (
          <Protected>
            <UserRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <User />,
          },
          {
            path: "add-user",
            element: (
              <Protected>
                <AddUser />
              </Protected>
            ),
          },
          {
            path: ":userId",
            id: "user-details",
            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <UserItem />
                  </Protected>
                ),
              },

              {
                path: "edit",
                element: (
                  <Protected>
                    <EditUser />
                  </Protected>
                ),
              },
              {
                path: "changepassword",
                element: (
                  <Protected>
                    <ChangePassword />
                  </Protected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "query",
        element: (
          <Protected>
            <QueryRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <Query />,
          },
          {
            path: ":queryId",
            id: "query-details",
            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <QueryItem />
                  </Protected>
                ),
              },
              // {
              //   path: "edit",
              //   element: <EditQuery />,
              // },
            ],
          },
        ],
      },
      {
        path: "contact-us",
        element: (
          <Protected>
            <ContactUsRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <ContactUs />,
          },
          {
            path: ":contactUsId",
            id: "contactus-details",
            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <ContactUsItem />
                  </Protected>
                ),
              },
              // {
              //   path: "edit",
              //   element: <EditEventPage />,
              //   action: manipulateEventAction,
              // },
            ],
          },
        ],
      },
      {
        path: "post",
        element: (
          <Protected>
            <PostRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <Post />,
          },
          {
            path: "add-post",
            element: <AddPost />,
          },
          {
            path: ":postId",
            id: "post-details",
            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <PostItem />
                  </Protected>
                ),
              },
              {
                path: "edit",
                element: <EditPost />,
              },
            ],
          },
        ],
      },
      {
        path: "contact",
        element: (
          <Protected>
            <ContactRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <VendorContactUs />,
          },
          {
            path: "add-contact",
            element: <AddContact />,
          },
          {
            path: ":contactId",
            id: "contact-details",
            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <VendorContactUsItem />
                  </Protected>
                ),
              },
              {
                path: "edit",
                element: <EditContactUs />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;
