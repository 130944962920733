import { Link } from "react-router-dom";
import { Row, Container, Col, Nav, Button } from "react-bootstrap";
import PropertyImg4 from "../assets/images/property-4.jpg";

function About() {
  return (
    <>
      <section className="page-header parallaxie">
        <Container>
          <Row>
            <Col md={12}>
              <div className="page-header-box">
                <h1 className="text-anime">About Us</h1>
                <nav className="wow fadeInUp" data-wow-delay="0.25s">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link href="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About us
                    </li>
                  </ol>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="why-choose-us">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} orderLg={2}>
              <div className="why-choose-us-image">
                <figure className="reveal image-anime">
                  <img src={PropertyImg4} alt="" />
                </figure>
              </div>
            </Col>
            <Col lg={6} orderLg={1}>
              <div className="why-choose-us-content">
                <div className="section-title">
                  {/* <h3 className="wow fadeInUp">All Home Desire</h3> */}
                  <h2 className="text-anime">All Home Desire</h2>
                </div>

                <div className="why-choose-us-body">
                  <p>
                    <strong>All Home Desire</strong> is a reputable real estate
                    Platform. The firm specializes in offering customers the
                    most up-to-date construction and dealing information. In
                    addition, we provide satisfying interior design solutions
                    and the best thing about us we provide both services at a
                    reasonable cost.
                  </p>
                  <p>
                    Besides being well equipped to meet all human needs, our
                    facilities are surrounded by international schools,
                    transportation options, shopping centers, and gardens. We
                    assist you with everything related to property research,
                    loans, investments, and EMI calculations.
                  </p>
                  <p>
                    We stay up to date with the latest trends in space
                    management and help our customers design their spaces with
                    cutting-edge designs.
                  </p>

                  {/* <Button href="#" className="btn-default">
                    Read More
                  </Button> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-section">
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <div className="card card-pro p-4">
                <div className="card-body">
                  <div className="card-icon">
                    <i class="fa fa-bullseye" aria-hidden="true"></i>
                  </div>
                  <h2 className="card-title">Mission</h2>
                  <p className="card-text fs-sm">
                    We understand the value of customer desires and for that, we
                    deliver top-notch services to our customers. We work on many
                    aspects for client satisfaction, and our long-term mission
                    is to make you happy in every possible way.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="card card-pro p-4">
                <div className="card-body">
                  <div className="card-icon">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </div>
                  <h2 className="card-title">Vision</h2>
                  <p className="card-text fs-sm">
                    To provide the best services, consultancy, and solutions to
                    our valuable customers to fulfill their "All Home Desire."
                    Our goal is to be a global leader in providing total home
                    solutions, whether it's for your ideal home or your dream
                    interior.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="how-it-works">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">
                {/* <h3>HOW IT WORKS</h3> */}
                <h2 className="text-anime">Why Choose Us?</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <div className="how-it-work-item item-work">
                <div className="icon-box">
                  <i class="fa fa-hashtag" aria-hidden="true"></i>

                  {/* <img src={PropertyHomeIcon} alt="" /> */}
                </div>

                {/* <h3>Find Property</h3> */}
                <p>
                  Providing services to fulfill customer desires in terms of
                  property buying, selling, and renting.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item item-work">
                <div className="icon-box">
                  <i class="fa fa-hashtag" aria-hidden="true"></i>

                  {/* <img src={RetailerHomeIcon} alt="" /> */}
                </div>

                {/* <h3>Meet Retailer</h3> */}
                <p>Provide best service with minimal cost.</p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item item-work">
                <div className="icon-box">
                  <i class="fa fa-hashtag" aria-hidden="true"></i>

                  {/* <img src={DocumentsHomeIcon} alt="" /> */}
                </div>

                {/* <h3>Documents</h3> */}
                <p>Align dedicated consultant for each customer.</p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item item-work">
                <div className="icon-box">
                  <i class="fa fa-hashtag" aria-hidden="true"></i>

                  {/* <img src={keysHomeIcon} alt="" /> */}
                </div>

                {/* <h3>Take the keys</h3> */}
                <p>Keep customer data private.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default About;
