import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Col, Container, Row, Button } from "react-bootstrap";
import * as Yup from "yup";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Api from "../../components/api/Api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const changePassword = Api({
    url: "admin/changePassword",
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const data = {
        userId: +userId,
        password: values.confirmPassword,
      };
      console.log(data);
      const responseData = await changePassword.postData(
        data,
        "multipart/form-data"
      );
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Password Changes Successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/user");
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Notification />
      <Container>
        <SingleDetailHeader
          header="Change Password"
          description="Change User Password details section"
        />
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            newPassword: Yup.string()
              .required("New Password is required")
              .min(6, "Password must be at least 6 characters"),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
              .required("Confirm Password is required"),
          })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Row>
                <Col>
                  <div>
                    <label htmlFor="newPassword">New Password</label>
                    <Field
                      type="password"
                      name="newPassword"
                      className="form-control"
                    />
                    <ErrorMessage name="newPassword" component="div" />
                  </div>
                </Col>
                <Col>
                  <div>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                    />
                    <ErrorMessage name="confirmPassword" component="div" />
                  </div>
                </Col>
              </Row>
              <button
                type="submit"
                className="btn btn-primary mt-5 mb-5"
                disabled={isSubmitting}
              >
                Change Password
              </button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default ChangePassword;
