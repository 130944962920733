import { React, Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import PropertyImg5 from "../../assets/images/01.webp";
import Button from "../common/button/Button";
import AddedTodayCard from "../homepage/cards/addedToday/AddedTodayCard";

export const CommercialSpaces = () => {
  return (
    <>
      <Row className="g-4">
        <Col md={6}>
          <AddedTodayCard
            imgUrl={PropertyImg5}
            forWhat="For Rental"
            title="   Luxury Rental Villa"
            address="118-11 Sutphin Blvd Jamaica, NY 11434"
            price="From $3,850"
          />
        </Col>
        <Col md={6}>
          <AddedTodayCard
            imgUrl={PropertyImg5}
            forWhat="For Rental"
            title="   Luxury Rental Villa"
            address="118-11 Sutphin Blvd Jamaica, NY 11434"
            price="From $3,850"
          />
        </Col>
        <Col md={6}>
          <AddedTodayCard
            imgUrl={PropertyImg5}
            forWhat="For Rental"
            title="   Luxury Rental Villa"
            address="118-11 Sutphin Blvd Jamaica, NY 11434"
            price="From $3,850"
          />
        </Col>
        <Col md={6}>
          <AddedTodayCard
            imgUrl={PropertyImg5}
            forWhat="For Rental"
            title="   Luxury Rental Villa"
            address="118-11 Sutphin Blvd Jamaica, NY 11434"
            price="From $3,850"
          />
        </Col>
      </Row>
    </>
  );
};

export default CommercialSpaces;
