import React from "react";
import { Link } from "react-router-dom";

const UserMenu = ({ userRoles }) => {
  const rolePages = {
    admin: [
      { to: "/user", label: "All User" },
      { to: "/contact", label: "Contact" },
    ],
    lead: [
      { to: "/lead-dashboard", label: "Lead Dashboard" },
      { to: "/contact", label: "Contact" },
    ],
    "team-member": [
      { to: "/team-member-dashboard", label: "Team Member Dashboard" },
      { to: "/contact", label: "Contact" },
    ],
    "front-desk": [
      { to: "/front-desk-dashboard", label: "Front Desk Dashboard" },
      { to: "/contact", label: "Contact" },
    ],
  };
  console.log(userRoles);
  const userRole = userRoles?.includes("ROLE_ADMIN")
    ? "admin"
    : "user" || userRoles?.includes("CUSTOMER")
    ? "lead"
    : "user";

  return (
    <div className="user-menu">
      <ul className="nav">
        {rolePages[userRole] &&
          rolePages[userRole].map((page, index) => (
            <li className="nav-item" key={index}>
              <Link className="nav-link" to={page.to}>
                {page.label}
              </Link>
            </li>
          ))}
        {/* Add more user menu items as needed */}
      </ul>
    </div>
  );
};

export default UserMenu;
