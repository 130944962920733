import React from "react";
import { StyledTypeCard } from "./Style";
import Button from "../../../common/button/Button";
import { Link } from "react-router-dom";

const TypeCard = ({ title, description, imageUrl, btnText }) => (
  <StyledTypeCard>
    <Link to="#" className="card card-pro">
      <img src={imageUrl} alt="" className="d-block mx-auto my-3" />
      <div className="card-body">
        <h2 className="card-title">{title}</h2>
        <p className="card-text fs-sm">{description}</p>
      </div>
      <div className="card-footer pt-0 border-0">
        <Button
          type="button"
          className="btn btn-outline-primary stretched-link"
        >
          {btnText}
        </Button>
      </div>
    </Link>
  </StyledTypeCard>
);

export default TypeCard;
