import React, { useState, useEffect } from "react";
import Api from "../../../admin/components/api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FloatingLabel,
} from "react-bootstrap";

import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/spinner/Spinner";
import SingleDetailHeader from "../../../admin/components/singleDetail/SingleDetailHeader";
import axios from "axios";

const EditContactUs = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [allPurpose, setAllPurpose] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState({});
  const [contactDetails, setContactDetails] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { contactId } = useParams();

  const getContactUsApi = Api({
    url: `user/contact/id/${contactId}`, // Endpoint to fetch contact details by ID
  });
  const updateContactUsApi = Api({
    url: `user/contact/edit`, // Endpoint to fetch contact details by ID
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    address: "",
    address1: "",
    street: "",
    apartment: "",
    zip_code: "",
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch contact details by ID
        const contactResponse = await getContactUsApi.fetchData(true);
        if (contactResponse.data.statusType === "SUCCESS") {
          setContactDetails(contactResponse.data.contact);

          console.log(contactResponse.data.contact);
        } else {
          showToast("Failed to fetch contact details", {
            type: "error",
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        showToast("Error Occurred", {
          type: "error",
        });
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      id: +contactId,
    };
    formData.forEach((value, key) => {
      data[key] = value;
    });

    console.log(data);

    try {
      setIsLoading(true);
      // Logic to update contact details
      const responseData = await updateContactUsApi.editData(data);
      setIsLoading(false);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Contact details updated successfully", {
          type: "success",
        });
        setTimeout(() => {
          navigate("/post");
        }, 3000);
      } else {
        showToast("Failed to update contact details", {
          type: "error",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      showToast("Error Occurred", {
        type: "error",
      });
      console.error("Error updating contact details:", error);
    }
  };
  return (
    <>
      <Notification />
      <SingleDetailHeader
        header="Edit Contact"
        description="Edit the contact details section"
      />
      <Container style={{ marginTop: "2rem" }}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="firstName" label="First Name">
                <Form.Control
                  type="text"
                  name="first_name"
                  defaultValue={contactDetails?.first_name || ""}
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="lastName" label="Last Name">
                <Form.Control
                  type="text"
                  name="last_name"
                  defaultValue={contactDetails?.last_name || ""}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="email" label="Email">
                <Form.Control
                  type="email"
                  name="email_address"
                  defaultValue={contactDetails?.email_address || ""}
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="mobile" label="Mobile Number">
                <Form.Control
                  type="text"
                  name="mobile_number"
                  defaultValue={contactDetails?.mobile_number || ""}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="address" label="Address">
                <Form.Control
                  type="text"
                  name="address"
                  defaultValue={contactDetails?.address || ""}
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="address1" label="Address 1">
                <Form.Control
                  type="text"
                  name="address1"
                  defaultValue={contactDetails?.address1 || ""}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="street" label="Street">
                <Form.Control
                  type="text"
                  name="street"
                  defaultValue={contactDetails?.street || ""}
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="apartment" label="Apartment">
                <Form.Control
                  type="text"
                  name="apartment"
                  defaultValue={contactDetails?.apartment || ""}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <FloatingLabel controlId="country" label="Country">
                <Form.Control
                  type="text"
                  name="country_name"
                  className="form-control"
                  defaultValue={contactDetails?.country_name || ""}
                ></Form.Control>
              </FloatingLabel>
            </Col>
            <Col md={4} className="mb-3">
              <FloatingLabel controlId="state" label="State">
                <Form.Control
                  type="text"
                  name="state_name"
                  defaultValue={contactDetails?.state_name || ""}
                ></Form.Control>
              </FloatingLabel>
            </Col>
            <Col md={4} className="mb-3">
              <FloatingLabel controlId="city" label="City">
                <Form.Control
                  type="text"
                  name="city_name"
                  defaultValue={contactDetails?.city_name || ""}
                ></Form.Control>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FloatingLabel controlId="zipCode" label="Zip Code">
                <Form.Control
                  type="text"
                  name="zip_code"
                  defaultValue={contactDetails?.zip_code || ""}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Button
            type="submit"
            className="btn btn-primary mt-3"
            disabled={isLoading}
          >
            {isLoading ? <Spinner loading={true} /> : "Update Contact"}
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default EditContactUs;
