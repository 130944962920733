import React from "react";
import { NavLink, Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";
import Col from "react-bootstrap/Col";

import PropertyImg4 from "../../assets/images/property-4.jpg";

const AboutUs2 = () => {
  return (
    <>
      <section className="why-choose-us ">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} orderLg={1}>
              <div className="why-choose-us-content">
                <div className="section-title">
                  <h3 className="wow fadeInUp">About Company</h3>
                  <h2 className="text-anime">The Core Company Values</h2>
                </div>

                <div className="why-choose-us-body">
                  <p>
                    We are constantly growing, learning, and improving and our
                    partners are steadily increasing. 200 projects is a sizable
                    number.
                  </p>
                  <ul>
                    <li>Interior Sketch</li>
                    <li>3d Modeling</li>
                    <li>2d Planning</li>
                  </ul>

                  {/* <Link href="#" class="btn-default">
                    Read More
                  </Link> */}
                </div>
              </div>
            </Col>
            <Col lg={6} orderLg={2}>
              <div className="why-choose-us-image">
                <figure className="reveal image-anime">
                  <img src={PropertyImg4} alt="" />
                </figure>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutUs2;
