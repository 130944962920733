import styled from "styled-components";

export const StyledOfferCard = styled.div`
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0.125rem 0.5rem -0.25rem #1f1b2d1f, 0 0.25rem 1rem #1f1b2d1f;

  .property-item:hover {
    box-shadow: 0 0.125rem 0.5rem -0.25rem #1f1b2d1f, 0 0.25rem 1rem #1f1b2d1f;
  }

  .featured-property .property-item {
    background-color: var(--white-color);
  }

  .property-item .property-header {
    position: relative;
    overflow: hidden;
  }

  .property-item .property-header figure img {
    width: 100%;
    transition: all 0.5s ease-out;
  }

  .property-item:hover .property-header figure img {
    transform: scale(1.2);
  }

  .property-item:hover .property-header figure:after {
    height: 250%;
    transition: all 600ms linear;
    background-color: transparent;
  }

  .property-item:hover .property-header figure:after {
    height: 250%;
    transition: all 600ms linear;
    background-color: transparent;
  }

  .property-item .property-header .property-label {
    position: absolute;
    top: 20px;
    left: 20px;
    background: var(--primary-color);
    color: var(--white-color);
    font-family: var(--accent-font);
    font-size: 16px;
    font-weight: 500;
    padding: 4px 14px;
    border-radius: 5px;
  }

  .property-item .property-body {
    padding: 20px 15px 10px 15px;
  }

  .property-item .property-body h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark-color);
  }

  .property-item .property-body p {
    margin-bottom: 0;
  }

  .property-item .property-meta {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--gray-divider);
  }

  .property-item .property-meta .property-amenity-item {
    width: 48%;
    margin-right: 4%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .property-item .property-meta .property-amenity-item:nth-of-type(2n + 2) {
    margin-right: 0;
  }

  .property-item .property-meta .property-amenity-item .icon-box {
    width: 16px;
    margin-right: 10px;
  }
  .property-amenity-item span {
    font-size: 13px;
  }
  .property-item .property-footer {
    padding: 0 30px 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .property-item .property-footer .property-price {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    color: var(--dark-color);
  }

  .property-item .property-footer .btn-default {
    font-size: 14px;
    padding: 5px 15px;
  }
`;
