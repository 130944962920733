import React, { useState, useEffect } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import AdminHeader from "../../common/Header";
import Button from "../../../components/common/button/Button";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import List from "../../components/listing/List";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
const PropertyListingForm = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  let endpoint;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const endPointFinalize = () => {
    if (userType === "SUPER_ADMIN" || userType === "ADMIN") {
      return `${baseUrl}/property/list`;
    } else {
      return `${baseUrl}/property/listByUser/${userId}`;
    }
  };

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);

  const handleDelete = async (itemId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/property/deleteProperty/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Property deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);

  const columns = [
    { displayName: "S.No.", key: "id" },
    { displayName: "Property Name", key: "name" },
    { displayName: "Location", key: "address" },
  ];
  useEffect(() => {
    endPointFinalize();

    const fetchPropertyData = async () => {
      const fetchData = async () => {
        try {
          endpoint = endPointFinalize();
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setData(response.data.properties);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    };

    fetchPropertyData();
  }, []);
  return (
    <>
      <Notification />
      <div className="content-body">
        <Container>
          {/* Delete Alert */}
          <BreadCrumb data="All Property" />
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            <Col md={2}>
              <AdminHeader />
            </Col>
            <Col md={10}>
              {data.length === 0 ? (
                <>
                  <Row>
                    <Col>
                      <AllMessage title="No Property Found" />
                    </Col>
                  </Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Link to={"/add-property"} className="btn btn-primary">
                      Add Property
                    </Link>
                  </Col>
                </>
              ) : (
                <List
                  title="All Property"
                  data={data}
                  columns={columns}
                  button="Add Property"
                  to="/add-property"
                  route="property"
                  handleDelete={handleDelete}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PropertyListingForm;
