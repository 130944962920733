import React, { useState } from "react";
import { Col, Container, Row, Pagination } from "react-bootstrap";
import { StyledOfferCard } from "../../components/homepage/cards/offerCard/Style";
import PropertyImg1 from "../../assets/images/property-1.jpg";
import PropertyImg2 from "../../assets/images/property-2.jpg";
import PropertyImg3 from "../../assets/images/property-3.jpg";
import PropertyImg4 from "../../assets/images/property-4.jpg";
import badroom from "../../assets/images/icon-badroom-dark.svg";
import bathroom from "../../assets/images/icon-bathroom.svg";
import area from "../../assets/images/icon-area.svg";
import garage from "../../assets/images/icon-garage.svg";
import countryIcon from "../../assets/images/country.png";
import rupeeIcon from "../../assets/images/rupee-indian.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Api from "../../admin/components/api/Api";
import OfferCard from "../homepage/cards/offerCard/OfferCard";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Spinner from "react-bootstrap/Spinner";
import AllMessage from "../common/allMessage/AllMessage";
function Properties() {
  const [isOpen, setIsOpen] = useState(false);
  const [allProperty, setAllProperty] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Property Type");
  // const [showSearchForm, setShowSearchForm] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [address, setAddress] = useState("");
  const [keyboards, setKeyboards] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 2;

  // const [minPrice, setMinPrice] = useState(0);
  // const [maxPrice, setMaxPrice] = useState(0);
  // const [searchResults, setSearchResults] = useState([]);

  const [priceRange, setPriceRange] = useState([1000, 100000]);
  const [sortBy, setSortBy] = useState("Price(low to high)");
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const handleSliderChange = (value) => {
    setMinValue(value[0]);
    setMaxValue(value[1]);
  };

  const getDetailsApi = Api({
    url: `https://services.allhomedesire.in/all/property/latestList`,
  });
  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const [isPurposeOpen, setIsPurposeOpen] = useState(false);
  const [selectedPurposeOption, setSelectedPurposeOption] =
    useState("Property Status");

  const handlePurposeOptionClick = (value) => {
    setSelectedPurposeOption(value);
    setIsPurposeOpen(false);
  };

  const [value, setValue] = useState(50);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const getPurposeApi = Api({
    url: "all/property/purposes",
  });
  const getTypesApi = Api({
    url: "all/property/types",
  });

  const getTypes = async () => {
    try {
      const responseData = await getTypesApi.fetchData();
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        setPropertyTypes(responseData?.data?.propertyTypes);
      } else {
        setPropertyTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPurpose = async () => {
    try {
      const responseData = await getPurposeApi.fetchData();
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        setPropertyPurposes(responseData?.data?.propertyPurposes);
      } else {
        setPropertyPurposes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      fetchData();
      setIsLoading(true);
      getPurpose();
      getTypes();

      setIsLoading(false);
    } catch (error) {}
  }, []);

  useEffect(() => {
    const sortedArray = sortPropertyByPrice(allProperty, sortBy);
    setAllProperty(sortedArray);
  }, [sortBy]);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const sortPropertyByPrice = (properties, sortBy) => {
    let sortedArray = [];
    switch (sortBy) {
      case "Price(low to high)":
        sortedArray = [...properties].sort((a, b) => a.price - b.price);
        break;
      case "Price(high to low)":
        sortedArray = [...properties].sort((a, b) => b.price - a.price);
        break;
      default:
        sortedArray = properties;
        break;
    }
    return sortedArray;
  };

  const sortedProperty = sortPropertyByPrice("Price(low to high)");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await axios.post(`${baseUrl}/all/property/latestList`, {
        keywords: keyboards || "",
        type_id: +selectedPropertyType,
        purpose_id: +selectedPurpose,
        address: address || "",
        min_price: 0,
        max_price: 0,
        page_number: currentPage,
        page_size: pageSize,
        order_by: "ASC",
      });
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setAllProperty(response.data.properties);

        if (response.data.properties.length < pageSize) {
          setTotalPages(currentPage);
        } else {
          setTotalPages(currentPage + 1);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    fetchData();
  };

  const handleTipFormatter = (value) => {
    return `$${value}`;
  };
  return (
    <>
      {isLoading ? (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {allProperty.length === 0 ? (
            <AllMessage title="No Property found" />
          ) : (
            <section className="all-section">
              <Container>
                <Row>
                  <Col md={3}>
                    <div className="widget">
                      <div className="widget-boxed main-search-field">
                        <div className="widget-boxed-header">
                          <h4>Search Your Desired Property</h4>
                        </div>
                        <div className="trip-search">
                          <form
                            className="form"
                            onSubmit={handleSearchFormSubmit}
                          >
                            <div className="form-nice-select looking">
                              <div className="first-select wide">
                                <div className="main-search-input-item">
                                  <input
                                    type="text"
                                    placeholder="Enter Keyword..."
                                    className="form-control"
                                    value={keyboards}
                                    onChange={(e) =>
                                      setKeyboards(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-nice-select looking">
                              <div className="first-select wide">
                                <div className="main-search-input-item">
                                  <input
                                    type="text"
                                    placeholder="address"
                                    className="form-control"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Property Type Filter */}
                            <div className="form-nice-select location">
                              <div className="dropdown-container">
                                <p>
                                  <i className="fa fa-home"></i> Filter by
                                  Property Type
                                </p>
                                <div
                                  className="dropdown-header"
                                  onClick={() => setIsOpen(!isOpen)}
                                >
                                  <span className="current">
                                    {selectedOption}
                                  </span>
                                  <i
                                    className={
                                      isOpen
                                        ? "fa fa-caret-up"
                                        : "fa fa-caret-down"
                                    }
                                  />
                                </div>
                                {isOpen && (
                                  <ul className="dropdown-list">
                                    {propertyTypes.map((propertyType) => (
                                      <li
                                        key={propertyType?.id}
                                        className="option"
                                        onClick={() => {
                                          handleOptionClick(propertyType?.name);
                                          setSelectedPropertyType(
                                            propertyType?.id
                                          );
                                        }}
                                      >
                                        {propertyType.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>

                            {/* Property Status Filter */}
                            <div className="form-nice-select location">
                              <div className="dropdown-container">
                                <p>
                                  <i className="fa fa-home"></i> Filter by
                                  Property Status
                                </p>
                                <div
                                  className="dropdown-header"
                                  onClick={() =>
                                    setIsPurposeOpen(!isPurposeOpen)
                                  }
                                >
                                  <span className="current">
                                    {selectedPurposeOption}
                                  </span>
                                  <i
                                    className={
                                      isPurposeOpen
                                        ? "fa fa-caret-up"
                                        : "fa fa-caret-down"
                                    }
                                  />
                                </div>
                                {isPurposeOpen && (
                                  <ul className="dropdown-list">
                                    {propertyPurposes.map((purpose) => (
                                      <li
                                        key={purpose?.id}
                                        className="option"
                                        onClick={() => {
                                          handlePurposeOptionClick(
                                            purpose?.name
                                          );
                                          setSelectedPurpose(purpose?.id);
                                        }}
                                      >
                                        {purpose?.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>

                            {/* Price Range Filter */}
                            <div className="form-nice-select location">
                              <div className="range-slider">
                                <p>₹ Filter by Price</p>
                                {/* <input
                                type="range"
                                min="1000"
                                max="100000"
                                value={value}
                                onChange={handleChange}
                              /> */}
                                <Slider
                                  min={1000}
                                  max={100000}
                                  step={1000}
                                  range
                                  defaultValue={[1000, 100000]}
                                  onChange={handleSliderChange}
                                  tipFormatter={handleTipFormatter}
                                  tipProps={{
                                    placement: "top",
                                    visible: true,
                                    getTooltipContainer: () =>
                                      document.getElementById(
                                        "slider-container"
                                      ),
                                  }}
                                />
                                <div className="ui-slider-handle">
                                  <div className="first-slider-value">
                                    ₹{minValue}
                                  </div>
                                  <div className="last-slider-value">
                                    ₹{maxValue}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              className="btn btn-primary mt-3 w-100"
                              type="submit"
                            >
                              Search
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={9}>
                    <Row className="headings-2 pt-0 align-items-center">
                      <div className="pro-wrapper">
                        <div className="detail-wrapper-body">
                          <div className="listing-title-bar">
                            <div className="text-heading text-left">
                              <p className="font-weight-bold mb-0 mt-3">
                                {allProperty.length} Search results
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="cod-pad single detail-wrapper mr-2 mt-0 d-flex justify-content-md-end align-items-center grid">
                          <div className="sort-list">
                            <label>
                              <i className="fa fa-align-left fs-16 pr-2"></i>
                              Sortby:
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => setSortBy(e.target.value)}
                            >
                              <option selected></option>
                              {/* <option value="Most Viewed">Most Viewed</option> */}
                              <option value="Price(low to high)">
                                Price(low to high)
                              </option>
                              <option value="Price(high to low)">
                                Price(high to low)
                              </option>
                            </select>
                          </div>
                          <div className="sorting-options">
                            <button
                              href="properties-list-1.html"
                              className="change-view-btn lde"
                            >
                              <i className="fa fa-th-list"></i>
                            </button>
                            <button className="change-view-btn active-view-btn">
                              <i className="fa fa-th-large"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      {allProperty.map((singleProperty) => (
                        <Col lg={4} md={6} className="p-2">
                          <OfferCard
                            title={singleProperty?.name}
                            description={singleProperty?.description}
                            imageUrl={singleProperty?.images[0]?.image_url}
                            btnText="See"
                            type={singleProperty?.type?.name}
                            badroom={badroom}
                            bedroomsSize={singleProperty?.structure?.bed_rooms}
                            bathroom={bathroom}
                            bathroomSize={singleProperty?.structure?.bath_rooms}
                            area={area}
                            areaSize={singleProperty?.area?.city?.name}
                            countryIcon={countryIcon}
                            country={
                              singleProperty?.area?.city?.state?.country?.name
                            }
                            price={singleProperty?.price}
                            rupee={rupeeIcon}
                            to={"property-details"}
                            id={singleProperty?.id}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row className="mt-5 mb-5">
                      <Pagination>
                        <Pagination.Prev
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        />
                        {Array.from({ length: totalPages }, (_, index) => (
                          <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                        />
                      </Pagination>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        </>
      )}
    </>
  );
}

export default Properties;
