import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Spinner from "../../../components/common/spinner/Spinner";
import Error from "../../../components/common/error/Error";
import axios from "axios";
import FileUploadField from "../../../components/fileUpload/FIleUploadField";

const EditPropertyImages = ({ imageData }) => {
  const [uploadedImageNames, setUploadedImageNames] = useState([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  console.log(imageData);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const uploadImages = async (values) => {
    console.log(values);
    const formData = new FormData();
    values.files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `${baseUrl}/all/upload/files/images`,
        formData
      );
      console.log(response);

      if (response.data.statusType === "SUCCESS") {
        console.log("hello from data");
        const imagePaths = response.data.propertyImages.map(
          (image) => image.imagePath
        );
        setUploadedImageNames(imagePaths);

        setIsImageUploaded(true);
      }
    } catch (error) {
      console.log(error);
      alert("Please select one or more attachments to upload.");
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          files: [],
        }}
        onSubmit={(values) => uploadImages(values)}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="card-body"></div>
            <div className="mb-3"></div>
            <Row>
              <Col md={12}>
                <div
                  className="mb-3"
                  style={{
                    padding: "2rem 2rem",
                  }}
                >
                  <Field name="files" component={FileUploadField} />
                  <ErrorMessage
                    name="files"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
            </Row>
            <div style={{ padding: "2rem 2rem" }}>
              <Button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner loading={true} /> : "Upload"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <Row className="mb-5 mt-5">
        {imageData?.images.map((singleImage) => (
          <Col md={6} style={{ marginTop: "1rem" }}>
            <img
              src={`https://images.allhomedesire.in/${singleImage?.image_url}`}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default EditPropertyImages;
